import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import { withTranslation, WithTranslation } from 'react-i18next';

import Delivery from '@kopapro/components/checkout/delivery/delivery';
import { M18ComboList, M18LookupList } from '@kopapro-redux/types/m18Option';
import { getM18Combo, getM18Lookup } from '@kopapro-redux/selectors/entities/m18Option';
import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';
import { getDeliveryMethodList } from '@kopapro-redux/selectors/entities/deliveryMethods';
import { ShippingRegionOptions } from '@kopapro-redux/types/shippingRegion';
import { getShippingRegionOptions } from '@kopapro-redux/selectors/entities/shippingRegion';
import { addDeliveryToOrder, checkDeliveryChargeInOrder } from '@kopapro-redux/actions/checkout';
import { CheckoutDelivery, CheckoutMember, ConfirmOrderParam } from '@kopapro-redux/types/checkout';
import { withLocation } from '@kopapro/components/commons/router-hoc';
import {
  cartRequireDelivery,
  getCartDelivery,
  getCartDeliveryId,
  getCartMember,
} from '@kopapro-redux/selectors/entities/cart';
import { UserInfo } from '@kopapro-redux/types/user';
import { getCurrentUserInfo, userLoggedIn } from '@kopapro-redux/selectors/entities/user';

interface IProps extends WithTranslation {
  location: any;
  confirmParam: ConfirmOrderParam;
  updateConfirmParam: Function;
  componentPrevious: Function;
  componentNext: Function;
}

interface StateProps {
  userInfo: UserInfo;
  userLoggedIn: boolean;
  member?: CheckoutMember;
  deliveryId: number;
  delivery?: CheckoutDelivery;
  combo?: { [k: string]: M18ComboList };
  lookup?: { [k: string]: M18LookupList };
  deliveryMethods: DeliveryMethod[];
  shippingRegionOptions: ShippingRegionOptions;
  isRequireDelivery: boolean;
}

interface DispatchProps {
  saveDelivery: typeof addDeliveryToOrder;
  checkCharge: typeof checkDeliveryChargeInOrder;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    userInfo: getCurrentUserInfo(state),
    userLoggedIn: userLoggedIn(state),
    member: getCartMember(state),
    deliveryId: getCartDeliveryId(state),
    delivery: getCartDelivery(state),
    combo: getM18Combo(state),
    lookup: getM18Lookup(state),
    deliveryMethods: getDeliveryMethodList(state),
    shippingRegionOptions: getShippingRegionOptions(state),
    isRequireDelivery: cartRequireDelivery(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  saveDelivery: addDeliveryToOrder,
  checkCharge: checkDeliveryChargeInOrder,
};

export type DeliveryProps = IProps & StateProps & DispatchProps;
export default withLocation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Delivery)));
