import { geti18nValue } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { ContactUsProps } from '@kopapro/components/shop/contactUs';
import utils from '@kopapro/utils/utils';
import ShopImage from '@kopapro/components/commons/shopImage';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { Trans } from 'react-i18next';
import DefaultModal from '@kopapro/components/commons/modals';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { contactUsConfig as config } from '@kopapro/utils/config';
import CountrySelect from '@kopapro/components/commons/inputs/countrySelect';

interface ContactUsState {
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    mobileCountry: string;
    mobile: string;
    message: string;
    agreed: boolean;
  };
  validated: boolean;
  sending: boolean;
  errorMessage: string;
  errorField: string;
  successMessage: string;
  showModal: boolean;
}

export default class ContactUsForm extends Component<ContactUsProps, ContactUsState> {
  showImage = config.showImage;
  defaultState = {
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      mobileCountry: '852',
      mobile: '',
      message: '',
      agreed: false,
    },
    validated: false,
    sending: false,
    errorMessage: '',
    errorField: '',
    successMessage: '',
    showModal: false,
  };

  constructor(props: ContactUsProps) {
    super(props);
    this.state = { ...this.defaultState };
  }
  showTerm = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showModal: true });
  };
  // handle term modal close
  handleClose = () => {
    this.setState({ showModal: false });
  };

  // handle form value change
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let newValue: any = e.currentTarget.value;
    // if not a number, then do not update value
    if (name === 'agreed') {
      newValue = e.currentTarget.checked;
    }

    if (name === 'mobile' && newValue.length > 0 && !/^[0-9\b]+$/.test(newValue)) {
      return;
    }
    const formData = { ...this.state.formData, [name]: newValue };
    this.setState({ formData });
  };

  handleSelect = (value: string) => {
    const formData = { ...this.state.formData, mobileCountry: value };
    this.setState({ formData });
  };

  getTermsLink(): JSX.Element {
    const { t } = this.props;
    return (
      <span onClick={this.showTerm} className="kpp-link">
        {t('ce01_pmpcore.react.termOfService')}
      </span>
    );
  }

  checkRequiredField = () => {
    const fields = ['firstName', 'lastName', 'email', 'mobile', 'message', 'agreed'];
    const stateValues: any = Object.assign({}, this.state.formData);
    const propsValues: any = Object.assign({}, this.props.content!);

    for (let field of fields) {
      const checkField = 'enable' + utils.capitalizeFirstLetter(field);
      const isFieldEnabled = propsValues[checkField];
      const fieldValue = stateValues[field];
      if (isFieldEnabled) {
        const isMobileField = field === 'mobile';
        const isFormField = this.state.formData.hasOwnProperty(field);
        if (isMobileField && utils.isEmpty(this.state.formData.mobileCountry)) {
          return 'mobileCountry';
        }

        if (!isFormField) {
          continue;
        }
        // TODO: check type and is empty value for different type in utils
        let isMissingValue = false;
        if (typeof fieldValue === 'boolean') {
          isMissingValue = fieldValue === false;
        } else {
          isMissingValue = utils.isEmpty(fieldValue);
        }

        if (isMissingValue) {
          if (isMobileField) {
            return 'phoneNumber';
          }
          return field;
        }
      }
    }
    return '';
  };

  resetForm = () => {
    this.setState({ formData: this.defaultState.formData, validated: false, errorMessage: '', errorField: '' });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { compId, submit } = this.props;
    const self = this;
    event.preventDefault();
    event.stopPropagation();

    let errorMessage = '';
    const errorField = this.checkRequiredField();

    // check email address
    const email = this.state.formData.email.trim();
    if (utils.isEmpty(email)) {
      errorMessage = 'ce01_pmpcore.react.emptyEmail';
    } else if (!utils.isValidEmail(email)) {
      errorMessage = 'ce01_pmpcore.react.invalidEmail';
    }
    if (!utils.isEmpty(errorField)) {
      errorMessage = 'ce01_pmpcore.kopapro.react.fieldNotEmpty';
      const isTermsNotAgreed = errorField === 'agreed';
      if (isTermsNotAgreed) {
        errorMessage = 'ce01_pmpcore.react.missingAgreeTerms';
      }
    }

    if (!utils.isEmpty(errorMessage)) {
      this.setState({ validated: true, errorMessage, errorField, successMessage: '' });
      return;
    }

    this.setState({ sending: true, errorMessage: '' });

    // dispatch request
    submit({ compId, data: this.state.formData }, function (isSuccess: boolean, message = '') {
      // update state errorMessage and successMessage
      if (isSuccess) {
        self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
        self.resetForm();
      } else {
        self.setState({ sending: false, validated: true, errorMessage: message, successMessage: '' });
      }
    });
  };

  getFormControl(fieldName: string, value: string, props: any = {}): ReactNode {
    const { t } = this.props;
    return (
      <Form.Control
        placeholder={t(`ce01_pmpcore.react.${fieldName}`)}
        name={fieldName}
        value={value}
        required
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
      />
    );
  }

  isShowImage = (): boolean => {
    const { photoCode } = this.props.content!;
    if (this.showImage && utils.isNotEmpty(photoCode)) {
      return true;
    }
    return false;
  };

  renderForm(): ReactNode {
    const { t, content } = this.props;
    const { enableAgreed, enableEmail, enableFirstName, enableLastName, enableMessage, enableMobile } = content!;
    const { validated, errorMessage, successMessage, sending, formData } = this.state;
    const { firstName, lastName, email, mobileCountry, mobile, message, agreed } = formData;
    return (
      <Col xs={12} md={this.isShowImage() ? 6 : 12} className="d-md-flex">
        <Card>
          <Card.Body>
            <Form noValidate action="/" onSubmit={this.handleSubmit} autoComplete="off">
              <Row className="field">
                {enableFirstName && <Col>{this.getFormControl('firstName', firstName, { readOnly: sending })}</Col>}
                {enableLastName && <Col>{this.getFormControl('lastName', lastName, { readOnly: sending })}</Col>}
              </Row>
              {enableEmail && (
                <Form.Group className="field" as={Col}>
                  {this.getFormControl('email', email, { type: 'email', readOnly: sending })}
                </Form.Group>
              )}
              {enableMobile && (
                <Row className="field">
                  <Form.Group as={Col}>
                    <CountrySelect
                      value={mobileCountry}
                      isSearchable={false}
                      onChange={this.handleSelect}
                      isDisabled={sending}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>{this.getFormControl('mobile', mobile, { readOnly: sending })}</Form.Group>
                </Row>
              )}
              {enableMessage && (
                <Form.Group className="field" as={Col}>
                  {this.getFormControl('message', message, { as: 'textarea', readOnly: sending })}
                </Form.Group>
              )}
              {enableAgreed && (
                <Form.Group className="field">
                  <Form.Check
                    name="agreed"
                    disabled={sending}
                    required
                    onChange={(e) => this.handleChange(e)}
                    checked={agreed}
                    label={
                      <Trans
                        t={t}
                        i18nKey="ce01_pmpcore.kopapro.react.readAndAgree"
                        components={[this.getTermsLink()]}
                      />
                    }
                  />
                </Form.Group>
              )}
              <ErrorMessage
                isShow={validated}
                message={t(errorMessage, {
                  field: utils.capitalizeFirstLetterOnly(t('ce01_pmpcore.react.' + this.state.errorField)),
                })}
              />
              <SuccessMessage message={t(successMessage)} />
              <Button disabled={sending} variant="main" type="submit" className=" w-100 text-uppercase">
                {t('ce01_pmpcore.react.submitBtn')}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    );
  }

  renderImage(): ReactNode {
    const { photoCode, overlayDto } = this.props.content!;

    if (!this.isShowImage()) {
      return null;
    }

    return (
      <Col xs={12} md={6} className="text-center my-auto">
        <ShopImage src={photoCode} className="p-2 img-fluid" overlay={overlayDto} alt="contact us cover image" />
      </Col>
    );
  }

  render(): ReactNode {
    if (utils.isUndefined(this.props.content)) {
      return null;
    }

    const { termsObj } = this.props.content!;
    return (
      <>
        {this.renderImage()}
        {this.renderForm()}
        <DefaultModal show={this.state.showModal} body={geti18nValue(termsObj)} onCloseHandler={this.handleClose} />
      </>
    );
  }
}
