import { CaptchaInfo, CaptchaPatterns, RegisterExtraFormData } from '@kopapro-redux/types/user';
import utils from '@kopapro-redux/utils/utils';
import KppBreadcrumb from '@kopapro/components/commons/breadcrumb/breadcrumb';
import Captcha from '@kopapro/components/commons/captcha';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import ShopImage from '@kopapro/components/commons/shopImage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import { registerConfig as config } from '@kopapro/utils/config';
import { AppImages } from '@kopapro/utils/constants/images';
import { geti18nValue, submitCaptchaDataParams } from '@kopapro/utils/m18';
import { ReactNode } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import React from 'react';
import { RegisterProps } from '@kopapro/components/pages/register';
import { Trans } from 'react-i18next';
import DefaultModal from '@kopapro/components/commons/modals';
import { Link, Navigate } from 'react-router-dom';
import InputForm, { InputFormState } from '@kopapro/components/commons/inputs/inputs';
import SmsMobileModal from '@kopapro/components/commons/verification/smsMobileModal';
import SmsVerifyModal from '@kopapro/components/commons/verification/smsVerifyModal';
import { M18ViewCheckMsg } from '@kopapro-redux/types/m18View';
import { InputValue, InputValues, InputField } from '@kopapro-redux/types/componentSetting';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

interface RegisterState extends InputFormState {
  password: string;
  confirmPassword: string;
  agreeTermsCond: boolean;
  acceptMarket: boolean;
  showTermsCondModal: boolean;
  errorMessage: string;
  successMessage: string;
  sending: boolean;
  captchaNonce: number;
  captchaInfo: CaptchaInfo;

  // sms
  showSmsMobileDialog: boolean;
  showSmsVerifyDialog: boolean;
  smsParamId: string;
  smsMobileCountry: string;
  smsMobile: string;
}

export default class Register extends InputForm<RegisterProps, RegisterState> {
  defaultState = {
    formData: {},
    imageMap: {},
    errorFields: {},

    password: '',
    confirmPassword: '',
    agreeTermsCond: false,
    acceptMarket: true,
    showTermsCondModal: false,
    errorMessage: '',
    successMessage: '',
    sending: false,
  };

  showImage = config.showImage;
  reCAPTCHAWidgetId = 'register_recaptcha';
  reCAPTCHAWidget: React.RefObject<any>;

  constructor(props: RegisterProps) {
    super(props);
    this.reCAPTCHAWidget = React.createRef();
    this.state = {
      ...this.defaultState,

      // captcha
      captchaNonce: new Date().getTime(),
      captchaInfo: {
        id: '',
        useCaptcha: this.props.useCaptcha,
        type: this.props.captchaType,
        selectedList: [],
      },

      // sms
      showSmsMobileDialog: false,
      showSmsVerifyDialog: false,
      smsParamId: '',
      smsMobileCountry: '852',
      smsMobile: '',
    };
  }

  componentDidMount() {
    const { captchaType } = this.props;
    const grecaptcha = window.grecaptcha;
    if (captchaType === 'google') {
      if (grecaptcha) {
        this.onRecaptchaReady();
      } else {
        window.addEventListener('recaptcha_ready', this.onRecaptchaReady);
      }
    }
    this.loadInitialData();
  }

  componentWillUnmount() {
    const { captchaType } = this.props;
    if (captchaType === 'google') {
      window.removeEventListener('recaptcha_ready', this.onRecaptchaReady);
    }
  }

  loadInitialData = () => {
    const { loadData } = this.props;
    const self = this;

    loadData({}, function () {
      self.setState({ sending: false });
    });
  };

  onRecaptchaReady = (e?: Event): void => {
    const { captchaType, captchaPublicKey } = this.props;
    const grecaptcha = window.grecaptcha;
    const self = this;
    if (captchaType === 'google' && grecaptcha) {
      grecaptcha.ready(function () {
        const widgetId = grecaptcha.render(self.reCAPTCHAWidgetId, {
          sitekey: captchaPublicKey,
        });
        const captchaInfo = { ...self.state.captchaInfo, id: widgetId };
        self.setState({ captchaInfo });
      });
    }
  };

  renderTop = () => {
    return <>{this.renderNav()}</>;
  };

  renderNav = () => {
    const { t } = this.props;
    const items = [
      { text: t('ce01_pmpcore.react.home'), to: '/', active: false },
      { text: t('ce01_pmpcore.react.register'), to: '', active: true },
    ];
    return <KppBreadcrumb items={items} />;
  };

  handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let newValue: any = e.currentTarget.value;

    if (name === 'password') {
      this.setState({ password: newValue });
    } else if (name === 'confirmPassword') {
      this.setState({ confirmPassword: newValue });
    } else if (name === 'acceptMarket') {
      newValue = e.currentTarget.checked;
      this.setState({ acceptMarket: newValue });
    } else if (name === 'agreeTermsCond') {
      newValue = e.currentTarget.checked;
      this.setState({ agreeTermsCond: newValue });
    }
  };

  isValidEmail = () => {
    let key: string = this.getFormFieldInputId('email') || '';
    const email: string = this.getFormFieldValue(key).trim();
    if (!utils.isEmpty(email) && !utils.isValidEmail(email)) {
      this.setErrorState('ce01_pmpcore.react.invalidEmail');
      return false;
    }
    return true;
  };

  handleRegister = (event: React.FormEvent<HTMLFormElement>) => {
    const { register, m18Fields: registerFields, navigate } = this.props;
    const { captchaInfo, agreeTermsCond, acceptMarket, password, confirmPassword } = this.state;
    const self = this;
    event.preventDefault();
    event.stopPropagation();
    // check email
    if (!this.isValidEmail()) {
      return;
    }

    // check required field
    let isExistEmpty: boolean = false;
    let requiredFields: string[] = this.checkRequiredFields();
    let errorFields: { [key: string]: string } = {};

    registerFields.forEach((item) => {
      if (requiredFields.includes(item.id)) {
        let emptyErrorMsg: string = 'ce01_pmpcore.react.fieldNotEmpty';
        errorFields = { ...errorFields, [item.id]: emptyErrorMsg };
        isExistEmpty = true;
      }
    });

    if (isExistEmpty) {
      this.setErrorState('ce01_pmpcore.react.registerFailed', errorFields);
      return;
    }
    // end

    // check password
    if (utils.isEmpty(password)) {
      this.setErrorState('ce01_pmpcore.react.emtpyPassword');
      return;
    }

    if (password !== confirmPassword) {
      this.setErrorState('ce01_pmpcore.react.passwordFail');
      return;
    }
    // end

    // check agree terms and condtion
    if (!agreeTermsCond) {
      this.setErrorState('ce01_pmpcore.react.missingAgreeTerms');
      return;
    }
    // end

    // handle form data
    const formData: RegisterExtraFormData = {
      inputs: this.toM18Values(),
    };

    this.setState({ sending: true, errorMessage: '', successMessage: '' });
    const { data, params } = submitCaptchaDataParams(captchaInfo);

    // dispatch request
    register(
      { formData, password, acceptMarket, data, params },
      function (status: boolean, checkMsg: M18ViewCheckMsg, needSMS: boolean, paramId: string, inputs: InputValues) {
        // update state errorMessage and successMessage
        let message: string = '';
        if (checkMsg) {
          message = geti18nValue(checkMsg.msg);
          if (checkMsg.info) {
            const mess: I18nDictionary = checkMsg.info?.mess || { en: '' };
            message = geti18nValue(mess);
          }
        }

        if (status) {
          let smsMobileCountry: string = self.getFormDataMobileCountry();
          let smsMobile: string = self.getFormDataMobile();
          let successMessage: string = '';
          let errorMessage: string = '';
          let showSmsMobileDialog: boolean = false;
          let showSmsVerifyDialog: boolean = false;
          let smsParamId: string = paramId;

          if (needSMS) {
            if (!utils.isEmpty(smsMobileCountry) && !utils.isEmpty(smsMobile)) {
              showSmsVerifyDialog = true;
            } else {
              showSmsMobileDialog = true;
              smsMobileCountry = '';
              smsMobile = '';
            }
          } else {
            successMessage = 'ce01_pmpcore.react.submitSuccess';
            smsMobileCountry = '';
            smsMobile = '';
            smsParamId = '';
            navigate('/login');
            return;
          }

          self.setState({
            sending: false,
            successMessage,
            errorMessage,
            errorFields,
            showSmsMobileDialog,
            showSmsVerifyDialog,
            smsParamId,
            smsMobileCountry,
            smsMobile,
          });
        } else {
          let errorFields: { [key: string]: string } = self.state.errorFields;

          // handle field error
          if (inputs) {
            Object.keys(inputs).forEach(function (key) {
              if (key !== 'password') {
                let item: InputValue = inputs[key];
                errorFields = { ...errorFields, [item.inputId]: geti18nValue(item.error) };
              }
            });
          }

          self.setErrorState(message, errorFields);
        }
      }
    );
  };

  getFormDataMobileCountry = () => {
    let key: string = this.getFormFieldInputId('mobile') || '';
    const mobile: string = this.getFormFieldValue(key);
    if (utils.isNotEmpty(mobile) && mobile.indexOf('@@') >= 0) {
      return mobile.split('@@')[0];
    }
    return '';
  };

  getFormDataMobile = () => {
    let key: string = this.getFormFieldInputId('mobile') || '';
    const mobile: string = this.getFormFieldValue(key);
    if (utils.isNotEmpty(mobile) && mobile.indexOf('@@') >= 0) {
      return mobile.split('@@')[1];
    }
    return '';
  };

  setErrorState = (message: string, errorFields: { [key: string]: string } = {}) => {
    if (Object.keys(errorFields).length > 0) {
      message = '';
    }

    this.setState({
      captchaNonce: new Date().getTime(),
      sending: false,
      successMessage: '',
      errorMessage: message,
      errorFields,
      showSmsMobileDialog: false,
      showSmsVerifyDialog: false,
      smsParamId: '',
    });
  };

  onSelectedCaptcha = (id: string, selectedList: CaptchaPatterns) => {
    const captchaInfo = { ...this.state.captchaInfo, id, selectedList };
    this.setState({ captchaInfo });
  };

  showTermsCondModal = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showTermsCondModal: true });
  };

  getTermsLink(): JSX.Element {
    const { t } = this.props;
    return (
      <span onClick={this.showTermsCondModal} className="kpp-link">
        {t('ce01_pmpcore.react.termOfService')}
      </span>
    );
  }

  // term modal close
  handleTermsCondModalClose = () => {
    this.setState({ showTermsCondModal: false });
  };

  // sms mobile dialog
  handleSmsMobileDialogClose = () => {
    this.setState({ showSmsMobileDialog: false, smsMobileCountry: '', smsMobile: '', smsParamId: '' });
  };

  handleSmsMobileDialogConfirm = (smsMobileCountry: string, smsMobile: string) => {
    if (utils.isNotEmpty(smsMobileCountry) && utils.isNotEmpty(smsMobile)) {
      this.setState({ showSmsMobileDialog: false, showSmsVerifyDialog: true, smsMobileCountry, smsMobile });
    }
  };

  // sms verify dialog
  handleSmsVerifyDialogClose = () => {
    this.setState({ showSmsVerifyDialog: false, smsMobileCountry: '', smsMobile: '', smsParamId: '' });
  };

  handleSmsVerifyDialogConfirm = (status: boolean) => {
    if (status) {
      this.setState(this.defaultState);
      this.props.navigate('/login');
    }
  };

  renderPassword(): ReactNode {
    const { t } = this.props;
    const { password, confirmPassword, sending } = this.state;

    return (
      <Form.Group as={Col}>
        <Form.Control
          className="field"
          type="password"
          placeholder={t(`ce01_pmpcore.react.password`)}
          name={'password'}
          value={password}
          disabled={sending}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleValueChange(e)}
        />
        <Form.Control
          className="field"
          type="password"
          placeholder={t(`ce01_pmpcore.react.confirmPassword`)}
          name={'confirmPassword'}
          value={confirmPassword}
          disabled={sending}
          required
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleValueChange(e)}
        />
      </Form.Group>
    );
  }

  // override parent
  override renderM18Field = (item: InputField): ReactNode => {
    const { id } = item;
    const input = this.renderM18Input(item);
    const errorMessage = this.renderM18InputError(item);
    return (
      <Form.Group key={`order-extra-${id}`} className="mb-3" as={Col} controlId={id}>
        {input}
        {errorMessage}
      </Form.Group>
    );
  };

  override getFormControl(item: InputField, props: any = {}): ReactNode {
    const { id, type, maxLength, required } = item;
    const name = geti18nValue(item.name);
    props = { ...props, placeholder: name + (required ? '*' : '') };

    return (
      <Form.Control
        key={id}
        className="field"
        name={id}
        maxLength={maxLength}
        {...props}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e, type)}
      />
    );
  }

  renderTermsCond(): ReactNode {
    const { t } = this.props;
    const { sending, agreeTermsCond, acceptMarket } = this.state;
    return (
      <Form.Group className="field">
        <Form.Check
          name="agreeTermsCond"
          disabled={sending}
          required
          onChange={(e) => this.handleValueChange(e)}
          checked={agreeTermsCond}
          label={<Trans t={t} i18nKey="ce01_pmpcore.kopapro.react.readAndAgree" components={[this.getTermsLink()]} />}
        />
        <Form.Check
          name="acceptMarket"
          disabled={sending}
          required
          onChange={(e) => this.handleValueChange(e)}
          checked={acceptMarket}
          label={<Trans t={t} i18nKey="ce01_pmpcore.react.accpetMarket" />}
        />
      </Form.Group>
    );
  }

  renderCaptcha(): ReactNode {
    const { captchaInfo } = this.state;
    if (captchaInfo.useCaptcha) {
      switch (captchaInfo.type) {
        case 'builtIn':
          return <Captcha onSelected={this.onSelectedCaptcha} nonce={this.state.captchaNonce} />;
        case 'google':
          return <div className="mb-2" ref={this.reCAPTCHAWidget} id={this.reCAPTCHAWidgetId}></div>;
      }
    }
    return null;
  }

  renderForm(): ReactNode {
    const { t, m18Fields } = this.props;
    const { errorMessage, successMessage } = this.state;

    return (
      <Col xs={12} md={6} className="d-md-flex mx-auto">
        <Card className="mx-auto">
          <Card.Body>
            <Form noValidate onSubmit={this.handleRegister} autoComplete="off">
              <Form.Group as={Col}>
                {m18Fields.map((item, index) => {
                  return <div key={index}>{this.renderM18Field(item)}</div>;
                })}
              </Form.Group>

              {this.renderPassword()}

              {this.renderTermsCond()}

              {this.renderCaptcha()}

              <Button disabled={false} variant="main" type="submit" className="w-100 field text-uppercase">
                {t('ce01_pmpcore.react.register')}
              </Button>
            </Form>
            <Link to="/login" className="btn btn-link w-100 field text-uppercase">
              {t('ce01_pmpcore.react.signInTitle')}
            </Link>

            <ErrorMessage message={t(errorMessage)} />
            <SuccessMessage message={t(successMessage)} />
          </Card.Body>
        </Card>
      </Col>
    );
  }

  renderImage(): ReactNode {
    if (!this.showImage) {
      return null;
    }

    const { photoCode } = this.props;

    return (
      <Col xs={12} md={6} className="image-container order-sm-last my-auto">
        <ShopImage src={photoCode} fallbackImage={AppImages.registerPlaceholder} className="main-img ratio ratio-1x1" />
      </Col>
    );
  }

  render(): ReactNode {
    const { t, termAndCond, userLoggedIn } = this.props;
    const { showTermsCondModal, showSmsMobileDialog, showSmsVerifyDialog, smsMobileCountry, smsMobile, smsParamId } =
      this.state;
    if (userLoggedIn) {
      return <Navigate to="/account" />;
    }
    return (
      <div id={`register-view`} className={`register page-container container row px-xl-5 `}>
        {this.renderTop()}
        <div className="col-12">
          <h3 className="title">{t('ce01_pmpcore.react.register')}</h3>
        </div>
        {this.renderImage()}
        {this.renderForm()}
        <DefaultModal
          show={showTermsCondModal}
          body={geti18nValue(termAndCond)}
          onCloseHandler={this.handleTermsCondModalClose}
        />
        <SmsMobileModal
          show={showSmsMobileDialog}
          onConfirmHandler={this.handleSmsMobileDialogConfirm}
          onCloseHandler={this.handleSmsMobileDialogClose}
        />
        <SmsVerifyModal
          show={showSmsVerifyDialog}
          mobileCountry={smsMobileCountry}
          mobile={smsMobile}
          paramId={smsParamId}
          onConfirmHandler={this.handleSmsVerifyDialogConfirm}
          onCloseHandler={this.handleSmsVerifyDialogClose}
        />
      </div>
    );
  }
}
