import { Numeric } from '@kopapro-redux/types/products';
import { DefaultFont } from '@kopapro/utils/constants/constants';
import { format } from 'date-fns';
import reduxUtils from '@kopapro-redux/utils/utils';

var mailFormat =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

var integerRegex = /^[0-9\b]+$/;

var alphanumericRegex = /^[0-9a-zA-Z]+$/;

const isDefined = function (value: any) {
  return typeof value !== 'undefined';
};
const isUndefined = function (value: any) {
  return typeof value === 'undefined' || value === undefined;
};
const isEmptyList = function (array: any[] | undefined) {
  return isUndefined(array) || array === null || array!.length <= 0;
};
const isEmpty = (text: string | undefined) => {
  return isUndefined(text) || text === null || text!.trim().length === 0;
};
const isNumber = (test: number | undefined) => typeof test === 'number' && !isNaN(test);

const isValidEmail = (mail: string) => {
  if (mail.match(mailFormat)) {
    return true;
  }
  return false;
};

const isValidDate = (date: any, allowEmpty = false) => {
  const isValid = date !== null && date instanceof Date && !isNaN(date.getTime());
  if (!allowEmpty) {
    return isValid;
  }

  return date === null || isValid;
};

const isIntegerString = (input: string) => {
  if (integerRegex.test(input)) {
    return true;
  }
  return false;
};

const isAlphanumeric = (input: string) => {
  if (alphanumericRegex.test(input)) {
    return true;
  }
  return false;
};

const appendFontFamilyLink = (font: string) => {
  if (font && font !== '' && !DefaultFont.includes(font)) {
    const fontUrl = `https://fonts.googleapis.com/css2?family=${font}&display=swap`;

    let el: any = document.querySelector("link[href='" + fontUrl + "']");
    if (!el) {
      el = document.createElement('link');
      el.rel = 'stylesheet';
      el.href = fontUrl;
      document.head.appendChild(el);
    }
  }
};

const removeStyleElement = (elementId: string) => {
  const current = document.getElementById(elementId);
  if (current) {
    document.head.removeChild(current);
  }
};

const appendStyleElement = (style: HTMLStyleElement) => {
  removeStyleElement(style.id);
  document.head.appendChild(style);
};

const setDocumentTitle = (title: string) => {
  if (document) {
    document.title = title;
  }
};

const setDocumentMeta = (title: string | null, description: string | null, keywords: string | null) => {
  if (document) {
    if (typeof title === 'string') {
      document.title = title;
    }
    if (typeof description === 'string') {
      // @ts-ignore
      let descriptionElement = document.getElementsByTagName('meta')['description'];
      if (descriptionElement) {
        descriptionElement.content = description;
      }
    }
    if (typeof keywords === 'string') {
      // @ts-ignore
      const keywordElement = document.getElementsByTagName('meta')['keywords'];
      if (keywordElement) {
        keywordElement.content = keywords;
      }
    }
  }
};

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
const capitalizeFirstLetterOnly = (text: string) => {
  return capitalizeFirstLetter(text.toLowerCase());
};

const getSafetyNumber = (value: Numeric | number | string | undefined) => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return 0;
  }
  let numberValue: number = 0;

  if (typeof value === 'string') {
    numberValue = parseFloat(value);
  } else {
    numberValue = value;
  }
  if (!isNumber(numberValue)) {
    return 0;
  }
  return numberValue;
};

const getBooleanValue = (value: any): boolean => {
  return value.toString() === 'true';
};

const formatDateByPattern = (datetime: Date | number | string, pattern: string): string => {
  const datetimeObj = new Date(datetime);
  if (datetimeObj && datetimeObj.toString() !== 'Invalid Date') {
    return format(datetimeObj, pattern);
  }
  return '';
};

const utils = {
  ...reduxUtils,
  isDefined,
  isUndefined,
  isEmpty,
  isEmptyList,
  isNumber,
  isValidEmail,
  isValidDate,
  isIntegerString,
  isAlphanumeric,
  appendFontFamilyLink,
  removeStyleElement,
  appendStyleElement,
  capitalizeFirstLetter,
  capitalizeFirstLetterOnly,
  getSafetyNumber,
  getBooleanValue,
  formatDateByPattern,
  setDocumentTitle,
  setDocumentMeta,
};
export default utils;