import { Component, ReactNode } from 'react';
import { ImageWithTextProps } from '@kopapro/components/shop/imageWithText';
import { getCompHeight, getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ShopImage from '@kopapro/components/commons/shopImage';
import SafetyDOM from '@kopapro/components/commons/safetyDOM';
import utils from '@kopapro-redux/utils/utils';

export default class ImageWithText extends Component<ImageWithTextProps> {
  //TODO: put in utils if reuse
  getTextAlignClass = (alignment: string) => {
    let value = '';
    switch (alignment) {
      case 'left':
        value = 'text-left';
        break;
      case 'center':
        value = 'text-center';
        break;
      case 'right':
        value = 'text-end';
        break;
    }
    return value;
  };

  renderHeading(): ReactNode {
    const { enableHeadingStyle, headingDto, headingObj, headingAlignment } = this.props.content!;

    let styleTitle = {};
    if (enableHeadingStyle) {
      styleTitle = getCustomStyle({ ...headingDto, useUdfStyle: true });
    }

    return (
      <div className={`image-with-text-heading ${this.getTextAlignClass(headingAlignment)}`}>
        <h5 style={{ ...styleTitle }}>{geti18nValue(headingObj)}</h5>
      </div>
    );
  }

  renderDetailBtn(): ReactNode {
    const { hideButton } = this.props.content!;
    if (hideButton) {
      return null;
    }

    const { buttonLabelObj, buttonAlignment, url } = this.props.content!;

    const textClass = this.getTextAlignClass(buttonAlignment);

    return (
      <div className={textClass}>
        <Link to={url} className={`btn btn-main`}>
          {geti18nValue(buttonLabelObj)}
        </Link>
      </div>
    );
  }

  renderImage(photoCode: string): ReactNode {
    const { heightOption, udfHeight } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight);
    return <ShopImage src={photoCode} height={height} alt="" />;
  }

  renderImageList(): ReactNode {
    const { photoCode, photoCodeList, heightOption, udfHeight } = this.props.content!;
    const { url, urlBuildInPhoto } = this.props.content!;
    const height = getCompHeight(heightOption, udfHeight);

    if (utils.isNotEmptyList(photoCodeList)) {
      let component: any = undefined;
      let componentProps = { to: '' };
      if (utils.isNotEmpty(url) && urlBuildInPhoto) {
        component = Link;
        componentProps = { to: url };
      }

      return (
        <Carousel controls={photoCodeList.length > 1} style={{ height }} interval={null} indicators={false}>
          {photoCodeList.map((item, i) => {
            return (
              <Carousel.Item key={`${item}_${i}`} as={component} {...componentProps}>
                {this.renderImage(item)}
              </Carousel.Item>
            );
          })}
        </Carousel>
      );
    }

    if (utils.isNotEmpty(url) && urlBuildInPhoto) {
      return <Link to={url}>{this.renderImage(photoCode)}</Link>;
    }
    return this.renderImage(photoCode);
  }

  renderContent(): ReactNode {
    const { detailObj } = this.props.content!;
    return <SafetyDOM html={geti18nValue(detailObj)} />;
  }

  render(): ReactNode {
    const { compId, content } = this.props;
    if (utils.isEmptyJSONObject(content)) {
      return null;
    }

    const { textPos } = content!;
    return (
      <Container fluid={'md'}>
        <div id={`image-with-text-${compId}`} className={`image-with-text`}>
          <Row>
            <Col xs={12} md={6} className="text-center">
              {this.renderImageList()}
            </Col>
            <Col xs={12} md={6} className={`order-md-${textPos === 'l' ? 'first' : 'last'}`}>
              {this.renderHeading()}
              {this.renderContent()}
              {this.renderDetailBtn()}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
