import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import AppControl from '@kopapro/components/misc/appControl/appControl';

import { getMetaBasicSetup, getMetaSeoSetup } from '@kopapro-redux/selectors/entities/shop';
import { getNumberOfItemsInCart } from '@kopapro-redux/selectors/entities/cart';
import { geti18nValue } from '@kopapro-redux/utils/m18';
import { getShopName } from '@kopapro-redux/selectors/entities/shop';
import { withLocation } from '@kopapro/components/commons/router-hoc';
interface IProps {
  location: any;
}

interface StateProps {
  checkShopCart: boolean;
  numberOfCartItems: number;
  companyName: string;
  seo: any;
}

interface DispatchProps {}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  return {
    companyName: geti18nValue(getShopName(state)),
    checkShopCart: getMetaBasicSetup(state).checkShopCart,
    numberOfCartItems: getNumberOfItemsInCart(state),
    seo: getMetaSeoSetup(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {};

export type AppControlProps = IProps & StateProps & DispatchProps;

export default withLocation(connect(mapStateToProps, mapDispatchToProps)(AppControl));
