import { Component, ReactNode, lazy, Suspense } from 'react';
import {
  geti18nValue,
  getRetailUnitPrice,
  isShowRetailUnitPrice,
  resolveProductItemImageURL,
} from '@kopapro/utils/m18';
import ShopImage from '@kopapro/components/commons/shopImage';
import { ItemsProps } from '@kopapro/components/order/items';
import { cartItemConfig } from '@kopapro/utils/config';
import utils from '@kopapro-redux/utils/utils';
import { Link } from 'react-router-dom';
import { OrderItem } from '@kopapro-redux/types/order';
import { formatBaseAmount, formatUnitPrice } from '@kopapro/utils/m18';
import RedeemIcon from '@mui/icons-material/Redeem';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { AppImages } from '@kopapro/utils/constants/images';
import ListGroup from 'react-bootstrap/ListGroup';
import CommentIcon from '@mui/icons-material/Comment';
import Loading from '@kopapro/components/commons/loading';
// import CommentModal from '@kopapro/components/order/items/commentModal';
import { Currency } from '@kopapro-redux/utils/constant';

const CommentModal = lazy(
  () => import(/* webpackChunkName: "CommentModal" */ '@kopapro/components/order/items/commentModal')
);

interface ItemsState {
  showCommentModal: boolean;
  selectItem?: OrderItem;
}

export default class Items extends Component<ItemsProps, ItemsState> {
  defaultState = {
    showCommentModal: false,
  };

  constructor(props: ItemsProps) {
    super(props);
    this.state = this.defaultState;
  }

  showCommentModal = (item: OrderItem) => {
    this.setState({ showCommentModal: true, selectItem: item });
  };

  handleCloseCommentModal = () => {
    this.setState({ showCommentModal: false });
  };

  getProductLink = (item: OrderItem) => {
    const { proGpCode, proCode, proGpId, proId } = item;

    if (!utils.isEmpty(proGpCode)) {
      return `/products/${proGpCode}`;
    } else if (!utils.isEmpty(proCode)) {
      return `/products/item/${proCode}`;
    }

    return `/proDetail/${proGpId}/${proId}`;
  };

  renderCommentModal(): ReactNode {
    const { isAllowComment } = this.props;
    const { selectItem } = this.state;
    if (!isAllowComment || utils.isUndefined(selectItem)) {
      return null;
    }

    return (
      <Suspense fallback={<Loading />}>
        <CommentModal
          orderId={this.props.orderId}
          item={selectItem!}
          show={this.state.showCommentModal}
          onCloseHandler={this.handleCloseCommentModal}
        />
      </Suspense>
    );
  }

  renderNameAndAttribute(item: OrderItem): ReactNode {
    const desc = geti18nValue(item.descObj);
    const attr = geti18nValue(item.attrObj);
    return (
      <div>
        <Link to={this.getProductLink(item)}>
          <h6>{desc}</h6>
        </Link>
        <div>
          {/* {attr.split(',').map((subAttribute, i) => {
            return (
              <span className="d-block text-muted" key={`${item.proCode}_attr_${i}`}>
                {subAttribute}
              </span>
            );
          })} */}
          <span className="d-block text-muted">{attr}</span>
        </div>
      </div>
    );
  }

  renderQuantity(item: OrderItem): ReactNode {
    return <div>{`x ${item.qty}`}</div>;
  }

  renderPrice(item: OrderItem): ReactNode {
    const { isPackSubPro, isGift, isRedemption } = item;
    if (isPackSubPro || (isGift && !isRedemption)) {
      return null;
    }

    const { oriUp, up, adpUp } = item;

    let price: any = `${formatUnitPrice(up, Currency.BASE)}`;

    const isShowRetail = isShowRetailUnitPrice(oriUp, up, adpUp);
    const retailUp = getRetailUnitPrice(oriUp, up, adpUp);

    if (isShowRetail) {
      price = (
        <>
          <div>
            <del>{`${formatUnitPrice(retailUp, Currency.BASE)}`}</del>
          </div>
          <div className="text-danger">{`${formatUnitPrice(up, Currency.BASE)}`}</div>
        </>
      );
    }

    return <>{price}</>;
  }

  renderAmount(item: OrderItem): ReactNode {
    const { t } = this.props;
    const { isPackSubPro, isGift, isRedemption } = item;
    if (isPackSubPro || (isGift && !isRedemption)) {
      return null;
    }

    const { amt } = item;
    return <div>{`${t('ce01_pmpcore.react.total')} : ${formatBaseAmount(amt)}`}</div>;
  }

  renderPromotion(item: OrderItem): ReactNode {
    const { isPackSubPro, isRedemption, isGift } = item;

    let remark: ReactNode = null;
    if (isPackSubPro) {
      // do nothing
    } else if (isRedemption) {
    } else if (isGift) {
    } else {
      remark = geti18nValue(item.ppDescObj);
    }
    return (
      <div>
        <div>{geti18nValue(item.discDescObj)}</div>
        <div>{remark}</div>
      </div>
    );
  }

  renderProductTypeIcon(item: OrderItem): ReactNode {
    const { t } = this.props;
    const { isPackSubPro, isRedemption, isGift } = item;

    let remark: ReactNode = null;
    let promotionDesc = geti18nValue(item.ppDescObj);
    if (!utils.isEmpty(promotionDesc)) {
      promotionDesc = `(${promotionDesc})`;
    }
    if (isPackSubPro) {
      remark = (
        <>
          <Inventory2OutlinedIcon /> {t('ce01_pmpcore.react.packageProduct')}
        </>
      );
    } else if (isRedemption) {
      remark = (
        <>
          <RedeemIcon /> {t('ce01_pmpcore.react.redemption')} {promotionDesc}
        </>
      );
    } else if (isGift) {
      remark = (
        <>
          <RedeemIcon /> {t('ce01_pmpcore.react.gift')} {promotionDesc}
        </>
      );
    }

    return <>{remark}</>;
  }

  renderProductReturned(item: OrderItem): ReactNode {
    const { t } = this.props;
    const { returnedQty } = item;
    if (returnedQty <= 0) {
      return null;
    }

    return <div>{`${t('ce01_pmpcore.react.returnProduct')} : ${returnedQty}`}</div>;
  }

  renderItem = (item: OrderItem): ReactNode => {
    const { t, isAllowComment } = this.props;
    const { photoCode, isPackSubPro, isExclude } = item;
    if (isExclude) {
      return null;
    }

    return (
      <ListGroup.Item key={`${item.proId}-${item.lot}`} className="cart-item">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className="position-relative">
              <ShopImage
                src={resolveProductItemImageURL(photoCode, item.isSV)}
                height={cartItemConfig.imageSize}
                width={cartItemConfig.imageSize}
                className="cover"
                fallbackImage={AppImages.productPlaceholder}
              />
              {/* <Badge className="d-inline d-sm-none qty-badge" pill bg="secondary">
                {qty}
              </Badge> */}
            </div>

            <div className="ms-3">
              {this.renderNameAndAttribute(item)}
              <div className="mt-1">{this.renderProductTypeIcon(item)}</div>
              <div className="mt-1">{this.renderPrice(item)}</div>
            </div>
          </div>
        </div>
        {isAllowComment && !isPackSubPro && (
          <div className="my-2">
            <button className="btn px-2" onClick={() => this.showCommentModal(item)}>
              <CommentIcon /> {t('ce01_pmpcore.react.comment')}
            </button>
          </div>
        )}
        <div className="my-2 d-flex justify-content-end">{this.renderQuantity(item)}</div>

        <div className="my-2 d-flex justify-content-between">
          {this.renderPromotion(item)}
          {this.renderAmount(item)}
        </div>

        <div className="d-flex justify-content-end">{this.renderProductReturned(item)}</div>
      </ListGroup.Item>
    );
  };

  render(): ReactNode {
    const { t, items } = this.props;

    if (utils.isEmptyList(items)) {
      return null;
    }

    return (
      <div className="section">
        <h4 className="sub-title">{t('ce01_pmpcore.react.cart')}</h4>
        <ListGroup>
          {items?.map((item) => {
            return this.renderItem(item);
          })}
        </ListGroup>
        {this.renderCommentModal()}
      </div>
    );
  }
}
