import keyMirror from "@kopapro-redux/utils/key-mirror";

// misc action type
export default keyMirror({
  CHECK_CART_ITEM_REQUEST: null,
  MODIFY_CART_ITEM_REQUEST: null,
  BATCH_MODIFY_CART_ITEM_REQUEST: null,
  REMOVE_PRODUCT_FROM_CART_REQUEST: null,
  SELECT_GIFT_REDEMPTION_FROM_CART_REQUEST: null,
  APPLY_REDEMPTION_TO_CART: null,
  APPLY_GIFT_TO_CART: null,
  GET_CART_REQUEST: null,
  SET_REQUESTING_CART: null,
  CART_UPDATED: null,
  CART_REMOVED: null,
  // cart page
  LOAD_CART_INFORMATION: null,
  PROMOTION_ALERT_UPDATED: null,
  MEMBER_TYPE_ALERT_UPDATED: null,
  REDEMPTION_LIST_UPDATED: null,
  GIFT_LIST_UPDATED: null,
});



