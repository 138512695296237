import { Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { formatDisplayAmount, geti18nValue, formatDate, formatTime, parseDatetime } from '@kopapro/utils/m18';
import { DeliveryProps } from '@kopapro/components/checkout/delivery';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import utils from '@kopapro/utils/utils';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import FormCheck from 'react-bootstrap/FormCheck';
import ErrorMessage from '@kopapro/components/commons/errorMessage';
import SuccessMessage from '@kopapro/components/commons/successMessage';
import PickUp from '@kopapro/components/checkout/delivery/pickup';
import { DeliveryMethod, PickupAddress } from '@kopapro-redux/types/deliveryMethod';
import ShopImage from '@kopapro/components/commons/shopImage';
import { checkoutConfig as config } from '@kopapro/utils/config';
import { ShippingRegionOption } from '@kopapro-redux/types/shippingRegion';
import { CheckoutDeliveryFormData } from '@kopapro-redux/types/checkout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DefaultModal from '@kopapro/components/commons/modals';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Select from 'react-select';
import CountrySelect from '@kopapro/components/commons/inputs/countrySelect';
import SpinnerButton from '@kopapro/components/commons/spinnerButton';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { AppImages } from '@kopapro/utils/constants/images';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TimePicker from '@kopapro/components/commons/time-picker-dropdown';
import { isDate, toDate, startOfDay, format } from 'date-fns';
interface DeliveryState {
  memberData: {
    email: string;
  };
  formData: CheckoutDeliveryFormData;
  expectedDate: Date | null;
  expectedTime: Date | null;
  dateErrorCode: any;
  timeErrorCode: any;
  isPickUp: boolean;
  selectedDeliveryMethod?: DeliveryMethod;
  selectedPickupAddress?: PickupAddress;
  selectedShippingRegion?: ShippingRegionOption;
  deliveryCharge: number;
  sending: boolean;
  errorMessage: string;
  successMessage: string;
  showModal: boolean;
}

export default class Delivery extends Component<DeliveryProps, DeliveryState> {
  imageSize = config.delivery.imageSize;

  defaultState = {
    memberData: {
      email: '',
    },
    formData: {
      shipId: 0,
      shipRegionId: 0,
      country: '',
      province: '',
      city: '',
      zipcode: '',
      company: '',
      shipAd1: '',
      shipAd2: '',
      shipAd3: '',
      shipAd4: '',
      firstName: '',
      lastName: '',
      mobileCountry: '852',
      mobile: '',
      shipRemark: '',
      pickShopId: 0,
    },
    expectedDate: null,
    expectedTime: null,
    dateErrorCode: null,
    timeErrorCode: null,
    selectedDeliveryMethod: undefined,
    selectedPickupAddress: undefined,
    selectedShippingRegion: undefined,
    deliveryCharge: 0,
    sending: false,
    errorMessage: '',
    successMessage: '',
    showModal: false,
    isPickUp: false,
  };

  constructor(props: DeliveryProps) {
    super(props);
    this.state = this.defaultState;
  }

  componentDidMount() {
    this.prepareInitialData();
  }

  componentDidUpdate(prevProps: DeliveryProps, prevState: Readonly<DeliveryState>) {
    if (
      (utils.isEmptyList(prevProps.deliveryMethods) && utils.isNotEmptyList(this.props.deliveryMethods)) ||
      (utils.isEmptyList(prevProps.shippingRegionOptions) && utils.isNotEmptyList(this.props.shippingRegionOptions))
    ) {
      this.prepareInitialData();
      return;
    }

    if (this.state.isPickUp !== prevState.isPickUp && utils.isNotEmpty(this.state.errorMessage)) {
      this.setState({ errorMessage: '' });
    }
  }

  prepareInitialData = () => {
    const { member, deliveryId, userLoggedIn, delivery } = this.props;
    let newFormData: CheckoutDeliveryFormData = this.defaultState.formData;
    let isPickUp = false;
    let expectedDate: Date | null = null;
    let expectedTime: Date | null = null;

    // if order have delivery record already
    if (utils.isNotEmptyM18Id(deliveryId)) {
      if (delivery) {
        newFormData = { ...newFormData, ...delivery };
        isPickUp = delivery.shipDetail.selfPickUp;
        expectedDate = parseDatetime(delivery.pickDate, delivery.pickTime);
        expectedTime = parseDatetime(delivery.pickDate, delivery.pickTime);
      }
    } else if (utils.isDefined(member)) {
      const { defaultDelivery } = member!;
      // if user logged in and have default delivery
      if (userLoggedIn && defaultDelivery) {
        newFormData = { ...newFormData, ...defaultDelivery };
      }
    }
    const { deliveryMethods, shippingRegionOptions } = this.props;
    if (utils.isNotEmptyList(deliveryMethods) && utils.isNotEmptyList(shippingRegionOptions)) {
      const { shipId, shipRegionId, pickShopId } = newFormData;
      let deliveryMethodOption = deliveryMethods.find((element) => element.m18Id === shipId);
      const shippingRegionOption = shippingRegionOptions.find((element) => element.value === shipRegionId.toString());
      const hasDelivey = deliveryMethods.some((item) => !item.selfPickUp);
      isPickUp = isPickUp || !hasDelivey;
      const pickupAddressOption = deliveryMethodOption?.selfPickUpAddr.find(
        (element) =>
          (pickShopId > 0 && element.shipRegionId === shipRegionId && element.shopId === pickShopId) ||
          (pickShopId <= 0 &&
            element.shipRegionId === shipRegionId &&
            element.shipAd1 === newFormData.shipAd1 &&
            element.shipAd2 === newFormData.shipAd2 &&
            element.shipAd3 === newFormData.shipAd3 &&
            element.shipAd4 === newFormData.shipAd4)
      );

      let newState = {
        selectedDeliveryMethod: deliveryMethodOption,
        selectedPickupAddress: pickupAddressOption,
        selectedShippingRegion: shippingRegionOption,

        memberData: { ...this.state.memberData, email: member?.email || '' },
        formData: { ...this.state.formData, ...newFormData },
        isPickUp,
        expectedDate,
        expectedTime,
      };

      if (utils.isEmpty(newState.formData.mobileCountry)) {
        newState.formData.mobileCountry = '852';
      }

      this.setState(newState, () => {
        // below action should be after first set state
        if (utils.isUndefined(deliveryMethodOption)) {
          // try  set  default
          // value of selfPickUp might be 0/1, returned by server
          const options = deliveryMethods.filter((element) => Boolean(element.selfPickUp) === isPickUp);
          if (options.length > 0) {
            deliveryMethodOption = options[0];
            this.selectDeliveryMethod(deliveryMethodOption);
            return;
          }
        }
        this.updateDeliveryCharge(shipId, shipRegionId);
      });
    }
  };

  showTerm = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ showModal: true });
  };

  // handle term modal close
  handleClose = () => {
    this.setState({ showModal: false });
  };

  // handle form value change
  changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = e.currentTarget.value;
    this.setState({ memberData: { ...this.state.memberData, email: newValue } });
  };

  getClearFormData = (method: DeliveryMethod) => {
    const { selectedDeliveryMethod: currentMethod } = this.state;
    if (!method.selfPickUp && currentMethod !== undefined && !currentMethod.selfPickUp) {
      return undefined;
    }

    let newFormData = this.defaultState.formData;

    const { userLoggedIn, member } = this.props;
    const { defaultDelivery } = member!;
    if (userLoggedIn && defaultDelivery && !method.selfPickUp) {
      newFormData = { ...this.defaultState.formData, ...defaultDelivery };
    }
    const { shipRegionId, shipAd1, shipAd2, shipAd3, shipAd4, country, province, city, zipcode } = newFormData;
    return { shipRegionId, shipAd1, shipAd2, shipAd3, shipAd4, country, province, city, zipcode };
  };

  selectDeliveryMethod = (method: DeliveryMethod) => {
    const { sending } = this.state;
    if (sending) {
      return;
    }
    const shipId = method?.m18Id || 0;
    // clear prefill data
    const reset = this.getClearFormData(method);

    const formData = { ...this.state.formData, shipId, ...reset };
    let shippingRegionOption: ShippingRegionOption | undefined = undefined;
    const { shippingRegionOptions } = this.props;
    if (utils.isNotEmptyList(shippingRegionOptions)) {
      const { shipRegionId } = formData;
      shippingRegionOption = shippingRegionOptions.find((element) => element.value === shipRegionId.toString());
    }
    this.setState(
      {
        selectedDeliveryMethod: method,
        selectedPickupAddress: undefined,
        selectedShippingRegion: shippingRegionOption,
        formData,
      },
      () => this.selectedShippingRegion(shippingRegionOption)
    );
  };

  switchIsPickup = (isPickUp: boolean) => {
    const { deliveryMethods } = this.props;
    if (this.state.sending) {
      return;
    }
    this.setState({
      isPickUp,
    });
    const filteredOptions = deliveryMethods.filter((method) => Boolean(method.selfPickUp) === isPickUp);
    if (utils.isNotEmptyList(filteredOptions)) {
      this.selectDeliveryMethod(filteredOptions[0]);
    }
  };

  selectPickupAddress = (address: PickupAddress | null) => {
    if (address === null) {
      this.setState(
        {
          selectedPickupAddress: undefined,
        },
        () => this.selectedShippingRegion(null)
      );
      return;
    }
    const { shipRegionId, shipAd1, shipAd2, shipAd3, shipAd4 } = address;
    const { shopId, shipAd1Obj, shipAd2Obj, shipAd3Obj, shipAd4Obj } = address;
    let ad1: string = shipAd1;
    let ad2: string = shipAd2;
    let ad3: string = shipAd3;
    let ad4: string = shipAd4;
    if (shopId > 0) {
      ad1 = geti18nValue(shipAd1Obj);
      ad2 = geti18nValue(shipAd2Obj);
      ad3 = geti18nValue(shipAd3Obj);
      ad4 = geti18nValue(shipAd4Obj);
    }

    const { shippingRegionOptions } = this.props;
    const shippingRegion = shippingRegionOptions?.find((element) => element.value === shipRegionId.toString());
    this.setState(
      {
        selectedPickupAddress: address,
        formData: {
          ...this.state.formData,
          shipRegionId,
          shipAd1: ad1,
          shipAd2: ad2,
          shipAd3: ad3,
          shipAd4: ad4,
          pickShopId: shopId,
        },
      },
      () => this.selectedShippingRegion(shippingRegion)
    );
  };

  getDefaultFormValue = (shipRegionId: number) => {
    const { userLoggedIn, member } = this.props;
    let memberDefaultData;
    // if user logged in and have default delivery
    if (shipRegionId > 0 && utils.isDefined(member) && userLoggedIn) {
      const { defaultDelivery } = member!;
      if (defaultDelivery && defaultDelivery.shipRegionId === shipRegionId) {
        memberDefaultData = defaultDelivery;
      }
    }
    const { country, province, city, zipcode } = memberDefaultData || this.defaultState.formData;
    return {
      country,
      province,
      city,
      zipcode,
    };
  };

  selectedShippingRegion = (option: ShippingRegionOption | null | undefined) => {
    const detail = option?.detail;
    let shipRegionId = detail ? detail.m18Id : 0;

    let { country, province, city, zipcode } = this.getDefaultFormValue(shipRegionId);
    if (detail) {
      country = utils.isNotEmpty(detail.country) ? detail.country : country;
      province = utils.isNotEmpty(detail.province) ? detail.province : province;
      city = detail.city ? detail.city : city;
      zipcode = detail.zipcode ? detail.zipcode : zipcode;
    }

    this.setState({
      selectedShippingRegion: option || undefined,
      formData: {
        ...this.state.formData,
        shipRegionId,
        country,
        province,
        city,
        zipcode,
      },
    });
    this.updateDeliveryCharge(this.state.formData.shipId, shipRegionId);
  };

  selectedMobileCountry = (value: string) => {
    this.setState({
      formData: {
        ...this.state.formData,
        mobileCountry: value,
      },
    });
  };

  handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.name;
    const newValue: any = e.currentTarget.value;
    this.setState({
      formData: { ...this.state.formData, [id]: newValue },
    });
  };

  setExpectedDate = (expectedDate: any) => {
    if (expectedDate === null) {
      this.setState({ expectedDate });
    } else if (isDate(expectedDate)) {
      this.setState({ expectedDate: toDate(expectedDate) });
    }
  };

  setExpectedTime = (expectedTime: any) => {
    if (expectedTime === null) {
      this.setState({ expectedTime });
    } else if (isDate(expectedTime)) {
      this.setState({ expectedTime: toDate(expectedTime) });
    }
  };

  updateDeliveryCharge = (shipId: number, shipRegionId: number) => {
    const { checkCharge } = this.props;
    const self = this;

    checkCharge({ shipId, shipRegionId }, function (isSuccess: boolean, deliveryCharge: number = 0) {
      self.setState({ deliveryCharge });
    });
  };

  validateBeforeSubmit = () => {
    const { isRequireDelivery } = this.props;

    const { formData, memberData, selectedDeliveryMethod, expectedDate, expectedTime, dateErrorCode, timeErrorCode } =
      this.state;
    const { shipId, shipRegionId } = formData;
    let email: string = memberData.email.trim();

    // check member data
    if (!this.props.userLoggedIn) {
      if (utils.isEmpty(email)) {
        return 'ce01_pmpcore.react.emptyEmail';
      } else if (!utils.isValidEmail(email)) {
        return 'ce01_pmpcore.react.invalidEmail';
      }
    }

    // check delivery data
    if (isRequireDelivery) {
      const { firstName, lastName, mobile, shipAd1 } = formData;
      if (utils.isEmptyM18Id(shipId)) {
        return 'ce01_pmpcore.react.chooseOneDelivery';
      }
      if (utils.isEmptyM18Id(shipRegionId)) {
        if (selectedDeliveryMethod && selectedDeliveryMethod.selfPickUp) {
          return 'ce01_pmpcore.kopapro.react.chooseRegionAndPickUpAddress';
        }
        return 'ce01_pmpcore.react.missShipRegion';
      }
      if (utils.isEmpty(firstName) || utils.isEmpty(lastName)) {
        return 'ce01_pmpcore.kopapro.react.missFirstLastName';
      }
      if (utils.isEmpty(mobile)) {
        if (selectedDeliveryMethod && selectedDeliveryMethod.enableMobile) {
          return 'ce01_pmpcore.kopapro.react.missMobile';
        }
      }
      if (utils.isEmpty(shipAd1)) {
        if (selectedDeliveryMethod && selectedDeliveryMethod.enableAddr) {
          return 'ce01_pmpcore.kopapro.react.missShipAddress';
        }
      }

      if (
        !utils.isValidDate(expectedDate, true) ||
        !utils.isValidDate(expectedTime, true) ||
        dateErrorCode === 'maxDate' ||
        timeErrorCode === 'invalidTime'
      ) {
        return 'ce01_pmpcore.kopapro.react.fieldInvalidDateTime';
      } else if (dateErrorCode) {
        return 'ce01_pmpcore.kopapro.react.dateAfterTodayOnly';
      }

      if (utils.isValidDate(expectedDate, false) && utils.isValidDate(expectedTime, false)) {
        const dateStr = format(expectedDate!, 'yyyy-MM-dd');
        const timeStr = format(expectedTime!, 'HH:mm:ss');
        const expectedDateTime = new Date(`${dateStr} ${timeStr}`);
        const currentDateTime = new Date();
        if (expectedDateTime.getTime() < currentDateTime.getTime()) {
          return 'ce01_pmpcore.kopapro.react.dateAfterTodayOnly';
        }
      }
    }
    return '';
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { saveDelivery } = this.props;
    const self = this;
    event.preventDefault();

    const { formData, memberData, expectedDate, expectedTime } = this.state;
    let email: string = memberData.email.trim();

    let errorMessage = this.validateBeforeSubmit();

    if (!utils.isEmpty(errorMessage)) {
      this.setState({ errorMessage, successMessage: '' });
      return;
    }

    this.setState({ sending: true, errorMessage: '', successMessage: '' });

    let pickDate = '',
      pickTime = '';
    if (expectedDate) {
      pickDate = formatDate(expectedDate);
    }
    if (expectedTime) {
      pickTime = formatTime(expectedTime);
    }
    // handle expexcted date and time
    // dispatch request
    saveDelivery({ email, formData: { ...formData, pickDate, pickTime } }, function (isSuccess: boolean, message = '') {
      if (isSuccess) {
        self.setState({ sending: false, successMessage: 'ce01_pmpcore.react.submitSuccess' });
        self.props.componentNext();
      } else {
        self.setState({ sending: false, errorMessage: message });
      }
    });
  };

  // Components
  getLoginLink(): JSX.Element {
    const { t, location } = this.props;
    const redirect = location.pathname;
    return (
      <Link to={`/login?redirect=${redirect}`} className="link">
        {t('mac_rnbase.react.login')}
      </Link>
    );
  }

  renderMember(): ReactNode {
    const { t } = this.props;
    const { userLoggedIn, userInfo } = this.props;
    const { sending } = this.state;
    return (
      <>
        <Card.Title>{t('ce01_pmpcore.kopapro.react.customerInfo')}</Card.Title>
        <hr />
        {userLoggedIn && <span>{`${t('ce01_pmpcore.react.onlineShopMember')} (${userInfo.email})`}</span>}
        {!userLoggedIn && (
          <>
            <p>
              <Trans t={t} i18nKey="ce01_pmpcore.kopapro.react.pleaseLogin" components={[this.getLoginLink()]} />
            </p>
            {/* <p>{t('ce01_pmpcore.react.treatAsTempUser')}</p> */}
            <Form.Control
              className="field mb-3"
              placeholder={t(`ce01_pmpcore.react.email`)}
              name={'email'}
              value={this.state.memberData.email}
              type="email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeEmail(e)}
              required
              disabled={sending}
            />
            <FormCheck
              className="field"
              type="checkbox"
              name="subscribedNews"
              checked={this.props.confirmParam.subscribedNews}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.props.updateConfirmParam({ subscribedNews: e.currentTarget.checked })
              }
              label={t('ce01_pmpcore.react.subscribedNews')}
              disabled={sending}
            />
          </>
        )}
      </>
    );
  }

  renderDeliveryMethodItem(item: DeliveryMethod): ReactNode {
    const { sending, formData } = this.state;
    const { id, m18Id, desc, image } = item;
    const name = geti18nValue(desc);
    const active = m18Id === formData.shipId;
    return (
      <div
        key={`delivery-method-item-${id}`}
        className={classnames('item', { active: active, diabled: active || sending })}
        onClick={() => {
          if (!active) {
            this.selectDeliveryMethod(item);
          }
        }}>
        <ShopImage height={this.imageSize} width={this.imageSize} alt="delivery method" src={image} />
        <h5 className="item-name text-truncate">{name}</h5>
      </div>
    );
  }

  getImageAndName = (isPickUp: boolean) => {
    const { t, deliveryMethods } = this.props;
    const filteredOptions = deliveryMethods.filter((method) => Boolean(method.selfPickUp) == isPickUp);
    const isOnlyOne = filteredOptions.length === 1;

    if (isOnlyOne) {
      const option = filteredOptions[0];
      return (
        <>
          <ShopImage height={this.imageSize} width={this.imageSize} alt="method" src={option.image} />
          <h5 className="item-name text-truncate">{geti18nValue(option.desc)}</h5>
        </>
      );
    }

    if (isPickUp) {
      return (
        <>
          <ShopImage height={this.imageSize} width={this.imageSize} alt="pickup method" src={AppImages.pickup} />
          <h5 className="item-name text-truncate">{t('ce01_pmpcore.kopapro.react.pickup')}</h5>
        </>
      );
    } else {
      return (
        <>
          <LocalShippingIcon sx={{ fontSize: 75 }} />
          <h5 className="item-name text-truncate">{t('ce01_pmpcore.kopapro.react.delivery')}</h5>
        </>
      );
    }
  };

  // buyer selet delivery / pick-up first
  renderDeliveryAndPickUpOption(): ReactNode {
    const { deliveryMethods } = this.props;
    const { sending, isPickUp } = this.state;
    const hasPickUp = deliveryMethods.some((item) => item.selfPickUp);
    const hasDelivey = deliveryMethods.some((item) => !item.selfPickUp);
    // no need to show options if not both
    return (
      <Form.Group className="mb-3">
        <div className="methods">
          {hasDelivey && (
            <div
              key={`delivery-method-item-delivery`}
              className={classnames('item', { active: !isPickUp, diabled: sending })}
              onClick={() => this.switchIsPickup(false)}>
              {this.getImageAndName(false)}
            </div>
          )}
          {hasPickUp && (
            <div
              key={`delivery-method-item-pickup`}
              className={classnames('item', { active: isPickUp, diabled: sending })}
              onClick={() => this.switchIsPickup(true)}>
              {this.getImageAndName(true)}
            </div>
          )}
        </div>
      </Form.Group>
    );
  }

  renderMethods(): ReactNode {
    const { t, deliveryMethods } = this.props;
    const { deliveryCharge: charge, selectedDeliveryMethod, isPickUp } = this.state;
    let deliveryChargeMessage = '';
    if (charge !== 0) {
      const amount = formatDisplayAmount(charge);
      deliveryChargeMessage = t('ce01_pmpcore.react.needDeliveryCharge', { amount });
    }
    const showTerm = selectedDeliveryMethod?.showTerm;
    const filteredOptions = deliveryMethods.filter((method) => Boolean(method.selfPickUp) === isPickUp);
    const hideIfOnlyOne = filteredOptions.length <= 1;
    return (
      <Form.Group className="mb-3">
        <>
          {!hideIfOnlyOne && (
            <>
              <h6>
                {isPickUp ? t('ce01_pmpcore.kopapro.react.chooseOnePickup') : t('ce01_pmpcore.react.chooseOneDelivery')}
              </h6>

              <div className="methods">
                {filteredOptions.map((item) => {
                  return this.renderDeliveryMethodItem(item);
                })}
              </div>
            </>
          )}
        </>

        {showTerm && (
          <div>
            <Button className="modal-btn link-secondary" onClick={this.showTerm} variant="link">
              {t('ce01_pmpcore.kopapro.react.order.showTermPolicy')}
              <HelpOutlineIcon fontSize="small" />
            </Button>
          </div>
        )}
        <h6 className="text-danger mb-3">{deliveryChargeMessage}</h6>
      </Form.Group>
    );
  }

  renderPickupAddress(): ReactNode {
    const { selectedDeliveryMethod: selectedMethod, selectedPickupAddress, sending } = this.state;
    const selectedCode = selectedPickupAddress?.shipCode;

    if (!selectedMethod || !selectedMethod.selfPickUp || utils.isEmptyList(selectedMethod.selfPickUpAddr)) {
      return null;
    }

    return (
      <PickUp
        addressList={selectedMethod.selfPickUpAddr}
        selectedCode={selectedCode}
        selectHandler={this.selectPickupAddress}
        sending={sending}
      />
    );
  }

  isHiddenIfPickUp = (name: string) => {
    const { isPickUp, selectedDeliveryMethod } = this.state;
    const selfPickup = isPickUp || selectedDeliveryMethod?.selfPickUp || false;
    const list = ['country', 'province', 'city', 'zipcode', 'shipAd1', 'shipAd2', 'shipAd3', 'shipAd4', 'company'];
    if (selfPickup) {
      if (list.includes(name)) {
        return true;
      }
    }
    return false;
  };

  getDeliveryFormGroup(label: string, name: string, props: any = {}, col = 6): ReactNode {
    const { t } = this.props;
    const { sending, formData } = this.state;
    const required = props.required || false;
    const value = formData[name as keyof CheckoutDeliveryFormData] || '';
    const isHidden = this.isHiddenIfPickUp(name);
    return (
      <Form.Group key={props.name} as={Col} lg={col} className={classnames('mb-3', { 'd-none': isHidden })}>
        <Form.Label className="delivery-input-label" required={required}>
          {t(label)}
        </Form.Label>
        <Form.Control
          className="field"
          name={name}
          value={value}
          {...props}
          disabled={props.disabled || sending}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChange(e)}
        />
      </Form.Group>
    );
  }

  // for easiler override and add control
  renderExpectedInputs = () => {
    const self = this;
    const { t } = this.props;
    const { selectedDeliveryMethod, selectedPickupAddress, sending } = this.state;
    const selfPickup = selectedDeliveryMethod?.selfPickUp || false;
    const today = startOfDay(new Date());
    if (selfPickup && selectedPickupAddress) {
      return (
        <Row>
          <DatePicker
            value={this.state.expectedDate}
            inputFormat="yyyy-MM-dd"
            onChange={(value) => this.setExpectedDate(value)}
            // disablePast={true}
            minDate={today}
            onError={function (reason: any) {
              self.setState({ dateErrorCode: reason });
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Form.Group as={Col} lg={6} className={'mb-3 d-flex align-items-end flex-column date-picker'}>
                <Form.Label className="delivery-input-label align-self-start">
                  {t('ce01_pmpcore.kopapro.react.expectedDateTime')}
                </Form.Label>
                <Form.Control ref={inputRef} {...inputProps} />
                {InputProps?.endAdornment}
              </Form.Group>
            )}
            disabled={sending}
          />
          <TimePicker
            className={'mb-3 col-lg-6 align-items-end time-picker flex-column align-self-end'}
            value={this.state.expectedTime}
            onChange={this.setExpectedTime}
            onError={function (reason: string) {
              self.setState({ timeErrorCode: reason });
            }}
          />
        </Row>
      );
    }

    return null;
  };

  renderForm(): ReactNode {
    const { t, shippingRegionOptions } = this.props;
    const { sending, selectedShippingRegion, selectedDeliveryMethod } = this.state;
    const { mobile, mobileCountry } = this.state.formData;

    const allowOverwrite = selectedShippingRegion?.detail.allowOverwrite || false;
    const isCountryReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.country);
    const isProvinceReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.province);
    const isCityReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.city);
    const isZipcodeReadonly = !allowOverwrite && utils.isNotEmpty(selectedShippingRegion?.detail.zipcode);

    const enableAddr = selectedDeliveryMethod?.enableAddr || false;
    const enableMobile = selectedDeliveryMethod?.enableMobile || false;
    const enableRemark = selectedDeliveryMethod?.enableRemark || false;
    const selfPickup = selectedDeliveryMethod?.selfPickUp || false;
    return (
      <div className="delivery-address">
        {!selfPickup && (
          <Row>
            <Form.Group className="mb-3">
              <Form.Label className="delivery-input-label" required={true}>
                {t('ce01_pmpcore.react.shipRegion')}
              </Form.Label>
              <Select<ShippingRegionOption>
                placeholder={t('ce01_pmpcore.react.select')}
                className="react-select-container"
                classNamePrefix="select"
                value={selectedShippingRegion}
                isSearchable={true}
                name="shipping-region"
                options={shippingRegionOptions}
                isDisabled={selfPickup || sending}
                onChange={this.selectedShippingRegion}
                noOptionsMessage={({ inputValue }) => t('ce01_pmpcore.react.noOptions')}
              />
            </Form.Group>
          </Row>
        )}
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.react.country', 'country', {
            maxLength: 40,
            disabled: selfPickup || isCountryReadonly,
          })}
          {this.getDeliveryFormGroup('ce01_pmpcore.react.stateProvince', 'province', {
            maxLength: 40,
            disabled: selfPickup || isProvinceReadonly,
          })}
        </Row>
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.react.city', 'city', {
            maxLength: 40,
            disabled: selfPickup || isCityReadonly,
          })}
          {this.getDeliveryFormGroup('ce01_pmpcore.react.zipCode', 'zipcode', {
            maxLength: 10,
            disabled: selfPickup || isZipcodeReadonly,
          })}
        </Row>
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.react.company', 'company', {
            maxLength: 80,
          })}
        </Row>
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.kopapro.react.address1', 'shipAd1', {
            maxLength: 100,
            disabled: selfPickup,
            required: enableAddr,
          })}
          {this.getDeliveryFormGroup('ce01_pmpcore.kopapro.react.address2', 'shipAd2', {
            maxLength: 100,
            disabled: selfPickup,
          })}
        </Row>
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.kopapro.react.address3', 'shipAd3', {
            maxLength: 100,
            disabled: selfPickup,
          })}
          {this.getDeliveryFormGroup('ce01_pmpcore.kopapro.react.address4', 'shipAd4', {
            maxLength: 100,
            disabled: selfPickup,
          })}
        </Row>
        {this.renderExpectedInputs()}
        <Row>
          {this.getDeliveryFormGroup('ce01_pmpcore.react.firstName', 'firstName', {
            maxLength: 100,
            required: true,
          })}
          {this.getDeliveryFormGroup('ce01_pmpcore.react.lastName', 'lastName', {
            maxLength: 100,
            required: true,
          })}
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg={9} className="delivery-input">
            <Form.Label className="delivery-input-label" required={enableMobile}>
              {t('ce01_pmpcore.kopapro.react.contactMobile')}
            </Form.Label>
            <InputGroup>
              <CountrySelect
                value={mobileCountry}
                isSearchable={false}
                onChange={this.selectedMobileCountry}
                isDisabled={sending}
              />
              <Form.Control
                className="field"
                name="mobile"
                value={mobile}
                maxLength={30}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChange(e)}
                disabled={sending}
              />
            </InputGroup>
          </Form.Group>
        </Row>
        {enableRemark && (
          <Row>
            {this.getDeliveryFormGroup(
              'ce01_pmpcore.react.shipRemark',
              'shipRemark',
              {
                maxLength: 1000,
                as: 'textarea',
              },
              12
            )}
          </Row>
        )}
      </div>
    );
  }

  renderDelivery(): ReactNode {
    const { isRequireDelivery } = this.props;
    if (!isRequireDelivery) {
      return null;
    }

    const { t, userLoggedIn } = this.props;
    const { sending } = this.state;
    return (
      <>
        <Card.Title>{t('ce01_pmpcore.kopapro.react.deliveryInfo')}</Card.Title>
        <hr />

        {this.renderDeliveryAndPickUpOption()}
        {this.renderMethods()}
        {this.renderPickupAddress()}
        {this.renderForm()}

        {userLoggedIn && (
          <FormCheck
            className="field mb-3"
            type="checkbox"
            name="saveDeliveryToMem"
            checked={this.props.confirmParam.saveDeliveryToMem}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.props.updateConfirmParam({ saveDeliveryToMem: e.currentTarget.checked })
            }
            label={t('ce01_pmpcore.react.saveDeliveryToMem')}
            disabled={sending}
          />
        )}
      </>
    );
  }

  renderFooter(): ReactNode {
    const { t } = this.props;
    const { sending } = this.state;
    return (
      <div className="chectout-footer">
        <Button
          variant="outline-secondary"
          className="chectout-footer-btn"
          disabled={sending}
          onClick={() => this.props.componentPrevious()}>
          {t('ce01_pmpcore.react.backBtn')}
        </Button>
        <SpinnerButton
          disabled={sending}
          spinning={sending}
          variant="main"
          type="submit"
          className="chectout-footer-btn">
          {t('ce01_pmpcore.react.next')}
        </SpinnerButton>
      </div>
    );
  }

  render(): ReactNode {
    const { t } = this.props;
    const { selectedDeliveryMethod, successMessage, errorMessage } = this.state;
    const term = geti18nValue(selectedDeliveryMethod?.term);
    return (
      <div className="delivery">
        <Card>
          <Card.Body>
            <Form noValidate onSubmit={this.handleSubmit} autoComplete="off">
              <div className="mb-5">{this.renderMember()}</div>
              <div>{this.renderDelivery()}</div>

              <ErrorMessage message={t(errorMessage)} />
              <SuccessMessage message={t(successMessage)} />

              {this.renderFooter()}
            </Form>
          </Card.Body>
        </Card>

        <DefaultModal
          show={this.state.showModal}
          title={t('ce01_pmpcore.react.order.shipTermTitle')}
          body={term}
          onCloseHandler={this.handleClose}
        />
      </div>
    );
  }
}
