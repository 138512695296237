import { OverlayData } from '@kopapro-redux/types/componentSetting';
import { getCustomStyle, geti18nValue, resolveImageURL } from '@kopapro/utils/m18';
import { Component, Fragment, ReactNode } from 'react';
import Image from 'react-bootstrap/Image';
import Placeholder from 'react-bootstrap/Placeholder';
import { AppImages } from '@kopapro/utils/constants/images';

type Props = {
  src: string;
  className?: string;
  style?: any;
  width?: string | number;
  height?: string | number;
  caption?: string;
  alt?: string;
  overlay?: OverlayData;
  fallbackImage?: string; // if error then use
  needOverlayBG?: boolean;
};

class ShopImage extends Component<Props> {
  state = {
    isLoaded: false,
    isError: false,
  };

  handleImageLoaded() {
    this.setState({ isLoaded: true, isError: false });
  }

  handleImageError() {
    this.setState({ isLoaded: true, isError: true });
  }

  render(): ReactNode {
    const { fallbackImage: propsFallbackImage, overlay, needOverlayBG, ...rest } = this.props;
    let fallbackImage = this.props.fallbackImage || AppImages.placeholder;
    let src = resolveImageURL(this.props.src) || resolveImageURL(fallbackImage);

    if (!src || src === '') {
      return null;
    }
    let caption = '';
    let captionStyle = {};
    const { height, width } = this.props;
    if (overlay && overlay.useOverlay) {
      caption = geti18nValue(overlay.overlayObj) || overlay.overlay;
      const { fontColor, fontSize, fontStyle, font } = overlay;
      captionStyle = getCustomStyle({ fontColor, fontSize, fontStyle, font, useUdfStyle: true });
    }

    if (this.state.isError) {
      src = resolveImageURL(fallbackImage);
    }

    if (!this.state.isLoaded) {
      return (
        <Placeholder as="div" animation="glow" className={this.props.className} style={{ color: 'gray' }}>
          <Placeholder className="img-placeholder" xs={12} style={{ minHeight: height, width }} />
          <img
            alt="placeholder"
            style={{ display: 'none' }}
            src={src}
            onLoad={this.handleImageLoaded.bind(this)}
            onError={this.handleImageError.bind(this)}
          />
        </Placeholder>
      );
    }

    if (caption && caption.length > 0) {
      return (
        <div className="shop-image">
          {needOverlayBG && <div className="overlay-background" />}
          <div className="caption" style={captionStyle}>
            {caption}
          </div>
          <Image {...rest} src={src} />
        </div>
      );
    }

    return (
      <Fragment>
        <Image {...rest} src={src} />
      </Fragment>
    );
  }
}

// export default React.memo(ShopImage);
export default ShopImage;
