import React, { Component, ReactNode } from 'react';
import { PaymentProps } from '@kopapro/components/payment';
import Card from 'react-bootstrap/Card';
import utils from '@kopapro-redux/utils/utils';
// import QRCodeStyling from 'qr-code-styling';
import { OrderStatus, PayStatus } from '@kopapro-redux/utils/constant';
import PayPalButton from '@kopapro/components/payment/paypal';
import { PaymentMethods } from '@kopapro/utils/constants/constants';
import { paymentConfig as config } from '@kopapro/utils/config';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { AppImages } from '@kopapro/utils/constants/images';
import RemindMessage from '@kopapro/components/commons/remindMessage';
import Loading from '@kopapro/components/commons/loading';
import ErrorMessage from '@kopapro/components/commons/errorMessage';

import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';

let QRCodeStyling: any;

import(/* webpackChunkName: "QRCodeStyling" */ 'qr-code-styling').then((QRCodeStylingModule: any) => {
  QRCodeStyling = QRCodeStylingModule.default;
});

interface PaymentState {
  errorMessage: string;
  sending: boolean;
  isOrderLoaded: boolean;
  successMessage: string;
  onlinePaymentInfo: any;
}

type PayPalInfo = {
  id: string;
  paypalCur: string;
  paypalId: string;
};

type StripeInfo = {
  apiKey: string;
  reference_no: string;
  session_id: string;
  status: string;
  stripeAcctId: string;
};

type FPSInfo = {
  imgCode: string;
  qrCode: string;
  status: string;
};

let stripePromise: Promise<Stripe | null>;

const getStripe = (apiKey: string) => {
  if (!stripePromise) {
    stripePromise = loadStripe(apiKey);
  }
  return stripePromise;
};

export default class Payment extends Component<PaymentProps, PaymentState> {
  defaultState = {
    errorMessage: '',
    sending: false,
    isOrderLoaded: false,
    successMessage: '',
    onlinePaymentInfo: {},
  };

  checkStatusInterval: any = null;
  queryingOrderStatus = false;
  qrCodeRef: React.RefObject<any>;
  _isMounted = false;

  qrCodeStyling: any;

  constructor(props: PaymentProps) {
    super(props);
    this.qrCodeStyling = new QRCodeStyling({
      width: config.qrCode.widthHeight,
      height: config.qrCode.widthHeight,
      // image: '',
      dotsOptions: {
        color: config.qrCode.color,
        type: 'rounded',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 2,
      },
    });

    this.state = this.defaultState;
    this.qrCodeRef = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadOrder(true);
  }

  componentDidUpdate(prevProps: PaymentProps, prevState: PaymentState) {
    this.checkPayment();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.checkStatusInterval !== null) {
      clearInterval(this.checkStatusInterval);
    }
  }

  updateSafetyState(state: any) {
    if (this._isMounted) {
      this.setState({ ...state });
    }
  }

  handleOnlinePayment = (data: any) => {
    // handle qrcode to prevent re-render when get order
    const self = this;
    const { type, pass, info } = data;
    const isFPS = pass && type === PaymentMethods.FPS;
    if (isFPS) {
      const { qrCode } = info as FPSInfo;

      this.qrCodeStyling.update({
        data: qrCode,
        image: AppImages.fpsIcon,
      });
    }

    this.setState({ onlinePaymentInfo: data }, function () {
      if (self.qrCodeRef) {
        self.qrCodeStyling.append(self.qrCodeRef.current);
      }
    });
  };

  checkPayment = () => {
    const { orderId, orderStatus, navigate, payStatus, outstandingAmount, getOnlinePayment, isOnlinePayment } =
      this.props;
    const { isOrderLoaded } = this.state;
    const { type: paymentType } = this.state.onlinePaymentInfo;
    const self = this;

    if (isOrderLoaded) {
      if (orderStatus === OrderStatus.NA) {
        navigate('/cart', { replace: true });
      } else if (orderStatus === OrderStatus.CANCELLED || orderStatus === OrderStatus.COMPLETED) {
        navigate(`/order/${orderId}`, { replace: true });
      } else if (orderStatus === OrderStatus.CONFIRMED) {
        // if unpaid to paid , then go to order confirmation page
        if (payStatus === PayStatus.PAID) {
          navigate(`/order/${orderId}`, { replace: true });
        }
        // if is online payment, then send requset
        if (isOnlinePayment) {
          if (!this.state.sending && outstandingAmount > 0 && utils.isEmpty(paymentType)) {
            this.setState({ sending: true, errorMessage: '' });

            getOnlinePayment({ orderId }, function (status: any, data: any) {
              if (status) {
                self.handleOnlinePayment(data);
              } else {
                self.setState({ errorMessage: 'ce01_pmpcore.react.failOnlinePayment' });
              }
            });
          }
        } else {
          if (this.checkStatusInterval !== null) {
            clearInterval(this.checkStatusInterval);
          }
        }
      }
    }
  };

  loadOrder = (handleCallback = false) => {
    const { orderId, loadOrder } = this.props;
    if (this.queryingOrderStatus) {
      return;
    }
    this.queryingOrderStatus = true;
    const self = this;
    const callback = function (status: boolean, data: any) {
      self.queryingOrderStatus = false;
      if (handleCallback && self.loadOrderCallback) {
        self.loadOrderCallback(status, data);
      }
    };
    loadOrder({ orderId, stopPropagation: true }, callback);
  };
  loadOrderCallback = (status: boolean, data: any) => {
    const { isOnlinePayment } = this.props;
    if (status) {
      this.updateSafetyState({ isOrderLoaded: true });
      this.checkPayment();
      if (this.checkStatusInterval === null) {
        if (isOnlinePayment && data && data.payStatus === PayStatus.UNPAID) {
          this.checkStatusInterval = setInterval(this.loadOrder, config.interval);
        }
      }
    }
  };

  renderPayPal(): ReactNode {
    const { orderId } = this.props;
    const { id, paypalCur, paypalId } = this.state.onlinePaymentInfo.info! as PayPalInfo;
    return <PayPalButton clientId={paypalId} currency={paypalCur} kppOrderId={orderId} paypalOrderId={id} />;
  }

  renderStripe(): ReactNode {
    const { onlinePaymentInfo } = this.state;
    if (onlinePaymentInfo.type === PaymentMethods.STRIPE) {
      const { apiKey, session_id, stripeAcctId } = onlinePaymentInfo.info! as StripeInfo;
      let stripe;
      if (apiKey) {
        getStripe(apiKey).then(function () {
          if (stripeAcctId) {
            stripe = window.Stripe!(apiKey, {
              stripeAccount: stripeAcctId,
            });
          } else {
            stripe = window.Stripe!(apiKey);
          }

          if (stripe) {
            stripe
              .redirectToCheckout({
                sessionId: session_id,
              })
              .then(function (result: any) {
                console.error(result.error.message);
              });
          }
        });
      }
    }
    return <Loading showMessage={false} />;
  }

  renderFPSPayment(): ReactNode {
    // if PaymentMethods.FPS
    const { t } = this.props;

    return (
      <Card className="mt-3">
        <Card.Body>
          <div>
            <p>{t('ce01_pmpcore.react.payment.fpsStep1')} </p>
            <p>{t('ce01_pmpcore.react.payment.fpsStep2')} </p>
            <div className="qrcode text-center my-4" ref={this.qrCodeRef}></div>
            <p>{t('ce01_pmpcore.react.payment.fpsStep3')} </p>
            <p>{t('ce01_pmpcore.react.payment.fpsStep4')} </p>
          </div>
        </Card.Body>
      </Card>
    );
  }

  renderWeChatPay(): ReactNode {
    // if PaymentMethods.WECHAT_PAY
    return (
      <Card className="mt-3 ">
        <Card.Body>
          <div>
            <div className="qrcode" ref={this.qrCodeRef}></div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  rednerOfflinePayment(): ReactNode {
    const { t, orderId, orderCode } = this.props;
    const homeLink = (
      <Link to={`/`}>
        <h5>{orderCode}</h5>
      </Link>
    );
    const orderLink = (
      <Link to={`/order/${orderId}`}>
        <h5>here</h5>
      </Link>
    );
    return (
      <div className="mt-4 p-5 ">
        <div className="text-center">
          <CheckCircleOutlineRoundedIcon className="text-success" sx={{ fontSize: 64 }} />
        </div>
        <h1 className="my-3 text-center">{t('ce01_pmpcore.kopapro.react.thankYouOrder')}</h1>
        <h3 className="py-2 text-center">
          <Trans
            t={t}
            i18nKey="ce01_pmpcore.kopapro.react.orderCodeTitle"
            components={[orderLink]}
            values={{ code: orderCode }}
          />
        </h3>
        <p className="py-2 text-center fs-5">
          <Trans t={t} i18nKey="ce01_pmpcore.kopapro.react.shoppingCartMsg" components={[homeLink]} />
        </p>
      </div>
    );
  }

  // Components
  renderMethods(): ReactNode {
    const { t, isOnlinePayment } = this.props;
    const { errorMessage, isOrderLoaded } = this.state;
    const { type, pass } = this.state.onlinePaymentInfo;
    const isPayPal = pass && type === PaymentMethods.PAYPAL;
    const isFPS = pass && type === PaymentMethods.FPS;
    const isStripe = pass && type === PaymentMethods.STRIPE;
    const isQrCode = isFPS;
    const isOfflinePayment = isOrderLoaded && !isOnlinePayment;
    const showLoading = utils.isNotEmpty(errorMessage) && (!isOrderLoaded || !pass);
    return (
      <Card>
        <Card.Body>
          <div className="text-center">
            <ErrorMessage message={t(errorMessage)} />
          </div>
          {showLoading && <Loading showMessage={false} />}
          {isStripe && this.renderStripe()}
          {isPayPal && this.renderPayPal()}
          {isQrCode && (
            <>
              <p className="text-center text-danger">
                <RemindMessage message={t('ce01_pmpcore.react.doNotCloseQrCode')} />
              </p>
              {isFPS && this.renderFPSPayment()}
              {/* {this.renderWeChatPay()} */}
            </>
          )}
          {isOfflinePayment && this.rednerOfflinePayment()}
        </Card.Body>
      </Card>
    );
  }

  render(): ReactNode {
    if (utils.isEmpty(this.props.orderId)) {
      return null;
    }

    return <div className="page-container container-xl px-xl-5 checkout-payment">{this.renderMethods()}</div>;
  }
}
