
import { RootState } from '@kopapro-redux/store';
import { LayoutComponent, LayoutMenuComponent } from '@kopapro-redux/types/shop';
import { createSelector } from 'reselect';
import { M18RnMeta } from '@kopapro-redux/utils/constant';

export const getShopMeta = (state: RootState) => {
  return state.kopapro.shopMeta;
};

export const getFooter = (state: RootState) => {
  return state.kopapro.shopMeta.layouts.udffootermenu;
};
export const getNavbar = (state: RootState) => {
  return state.kopapro.shopMeta.layouts.udfmenu;
};

export const getBanners = (state: RootState) => {
  return state.kopapro.shopMeta.banner;
};

export const getShopComponents = (state: RootState) => {
  return state.kopapro.shopMeta.layouts.shop?.comps;
};

export const getComponentSetting = (state: RootState) => {
  return state.kopapro.shopMeta.componentSetting;
};

export const getCompany = (state: RootState) => {
  return state.kopapro.shopMeta.setup.company;
};

export const getPosPerference = (state: RootState) => {
  return state.kopapro.shopMeta.setup.posPrefernce;
};

export const getShopLoginSetup = (state: RootState) => {
  return state.kopapro.shopMeta.setup.login;
};

export const getShopOrderSetup = (state: RootState) => {
  return state.kopapro.shopMeta.setup.order;
};

export const getShopRegisterSetup = (state: RootState) => {
  return state.kopapro.shopMeta.setup.register;
};

export const getShopInitializing = (state: RootState) => {
  return state.kopapro.shopMeta.status.initializing;
};

export const isShowShopSplash = (state: RootState) => {
  return state.kopapro.shopMeta.status.showSplash;
};

export const getMetaBasicSetup = (state: RootState) => {
  return state.kopapro.shopMeta.setup.default;
};

export const getMetaSeoSetup = (state: RootState) => {
  return state.kopapro.shopMeta.setup.seo;
};

export const getMetaLanguageSetup = createSelector([getShopMeta], (shopMeta) => {
  if (shopMeta) {
    return shopMeta.setup.language;
  }
  return undefined;
});

const getChildren = (components: LayoutComponent[], current: LayoutComponent): LayoutMenuComponent[] => {
  const children = components.filter((comp) => comp.folder === current.compId);
  if (children.length === 0) {
    return [];
  }
  let allChildren: LayoutMenuComponent[] = [];
  children.forEach((c) => {
    let newChild: LayoutMenuComponent = Object.assign({ children: [] }, c);
    newChild.children = getChildren(components, c);
    allChildren.push(newChild);
  });
  return allChildren;
};

const construct = (components: LayoutComponent[]) => {
  let nodes = components
    .filter((comp) => {
      return comp.folder === '';
    })
    .map((folder): LayoutMenuComponent => {
      const children = getChildren(components, folder);
      return {
        ...folder,
        children,
      };
    });
  return nodes;
};

//  ==================== layouts ====================================
export const getFooterComponents = createSelector([getFooter], (footer) => {
  return construct(footer.comps);
});

export const getNavbarComponents = createSelector([getNavbar], (navbar) => {
  if (navbar) {
    return construct(navbar.comps);
  }
  return null;
});
//  ==================== componentSetting ====================================

export const getShopInfo = createSelector([getComponentSetting], (settings) => {
  const shopInfo = settings.shopinfo;
  if (shopInfo && shopInfo.length === 1) {
    return shopInfo[0];
  }
  return undefined;
});

export const getProductSetupContent = createSelector([getComponentSetting], (settings) => {
  const productsetup = settings.productsetup;
  if (productsetup && productsetup.length === 1) {
    return productsetup[0].content;
  }
  return undefined;
});

export const getProductFilters = createSelector([getProductSetupContent], (content) => {
  if (content) {
    return content.proQuickSearch;
  }
  return undefined;
});

export const isFilterEnabled = createSelector([getProductFilters], (proQuickSearch) => {
  let isEnabled = false;
  if (proQuickSearch) {
    const { enable, info } = proQuickSearch;
    isEnabled =
      Object.values(enable).some((value) => value === true) ||
      Object.values(info).some((field) => field.enabled === true);
  }
  return isEnabled;
});

export const getCustomBuyButtonStyles = createSelector([getProductSetupContent], (content) => {
  if (content) {
    const { enableProBuyBtnStyle, proBuyBtnStyle } = content;
    if (enableProBuyBtnStyle) {
      return proBuyBtnStyle;
    }
  }
  return undefined;
});

export const getAnnouncementData = createSelector([getShopInfo], (shopInfo) => {
  const { enableAnnouncementStyle, announcementStyle, announcement } = shopInfo || {};

  return {
    enableStyle: enableAnnouncementStyle || false,
    style: announcementStyle,
    announcement: announcement || {},
  };
});

// ===================== setup ===========================
export const getShopName = createSelector([getShopInfo], (shopInfo) => {
  if (shopInfo) {
    const { displayName, name } = shopInfo;
    if (displayName) {
      return displayName;
    } else if (name) {
      return name;
    }
  }
  return '';
});

export const getCustomCSS = createSelector([getShopInfo], (shopInfo) => {
  if (shopInfo) {
    return shopInfo.customCSS;
  }
  return undefined;
});

export const getLanguages = createSelector([getMetaLanguageSetup], (languageData) => {
  if (languageData) {
    const langs = Object.values(languageData)
      .filter((lang) => lang.show)
      .sort((a, b) => a.order - b.order);
    return langs;
  }
  return undefined;
});
export const isLanguageValid = createSelector([getLanguages, (state, lang: string) => lang], (langs, lang) => {
  if (langs && lang) {
    return langs.some((item) => item.id === lang);
  }
  return false;
});

export const getDataByComponent = createSelector(
  [getComponentSetting, (state, component: { compId: string; compName: string }) => component],
  // Output selector gets (`items, category)` as args
  (items, component) => {
    const attributeName = component.compName.toLowerCase(); // get by function later
    return items[attributeName]?.find((item: any) => item.compId === component.compId);
  }
);

export const getBanner = createSelector([getBanners, (state, compId: string) => compId], (banners, compId) => {
  if (banners && compId) {
    return banners[compId];
  }
  return undefined;
});

export const getDisplayShopComponents = createSelector([getShopComponents, getShopInfo], (components, info) => {
  if (components && info) {
    let skipDefaultProudct = info?.hideDefProList;

    return components.filter((component) => {
      if (skipDefaultProudct && component.compId === M18RnMeta.PRODUCT) {
        return false;
      }
      return true;
    });
  }
  return undefined;
});

export const getDesktopHeaderStyle = createSelector([getShopInfo], (shopInfo) => {
  if (shopInfo) {
    const { enablePageHeaderStyle, pageHeaderStyle, enablePageHeaderGridLayout } = shopInfo;
    if (!enablePageHeaderGridLayout && enablePageHeaderStyle) {
      return pageHeaderStyle;
    }
  }
  return undefined;
});

export const getMobileHeaderStyle = createSelector([getShopInfo], (shopInfo) => {
  if (shopInfo) {
    const { enableMobileHeaderStyle, mobileHeaderStyle, enableMobileSideBarGridLayout } = shopInfo;
    if (!enableMobileSideBarGridLayout && enableMobileHeaderStyle) {
      return mobileHeaderStyle;
    }
  }
  return undefined;
});