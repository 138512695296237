import { ShopSetup } from "@kopapro-redux/types/shop";
import { PreviewType, ShopMetaType } from '@kopapro-redux/action-types';
import { handlePreviewMetaSetup } from '@kopapro-redux/reducers/preview';

const defaultSetup: ShopSetup = {
  default: {
    checkShopCart: true,
    compressImage: true,
    copyRightOwner: '',
    currency: 'HKD',
    enableProComment: true,
    hideServProv: false,
    language: 'zh-CN',
    planLevel: 'Premium',
    enableLangPath: false,
    excludeDefaultLangInURL: false,
  },
  login: {
    blacklistMsg: {},
    captchaPublicKey: '',
    captchaType: '',
    promotionLogo: '',
    useCaptcha: false,
  },
  order: {
    allowMutiDiscCode: false,
    disableCancelOrder: false,
    isNeedVerify: false,
    enableCheckOutReminder: false,
    checkOutReminder: {},
  },
  seo: {},
};

const setupRedcuer = (state = defaultSetup, action: any): ShopSetup => {
  switch (action.type) {
    case ShopMetaType.GET_SHOP_SETUP_SUCCESS:
      const setup = action.payload;
      return {
        ...state,
        ...setup,
      };

    case PreviewType.PREVIEW_SHOP_SETUP:
      return handlePreviewMetaSetup(state, action.payload);

    default:
      return state;
  }
};

export default setupRedcuer;