import { Component } from "react";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { FilterProps } from "@kopapro/components/products/productList/filters";
import utils from "@kopapro/utils/utils";
import { formatAmount, geti18nValue, getCategoryName } from '@kopapro/utils/m18';
import MultiRangeSlider from '@kopapro/components/commons/multiRangeSlider';
import { Currency } from '@kopapro-redux/utils/constant';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { productListConfig as config } from '@kopapro/utils/config';
import classnames from 'classnames';
import { FieldInfo } from '@kopapro-redux/types/componentSetting';
interface FilterState {
  min: number;
  max: number;
  options?: { [name: string]: any[] };
}

class FilterMenu extends Component<FilterProps, FilterState> {
  isInitialized = false;
  defaultState = {
    min: 0,
    max: 0,
  };

  constructor(props: FilterProps) {
    super(props);
    const { category, appliedFilter, priceMin, priceMax } = props;
    let state: FilterState = { ...this.defaultState };

    if (category && category.proCnt > 0) {
      state = { min: parseFloat(priceMin), max: parseFloat(priceMax) };
    }

    if (appliedFilter) {
      const { priceFrom, priceTo, ...rest } = appliedFilter;
      state = { min: priceFrom, max: priceTo, options: rest as { [name: string]: string[] } };
    }
    this.state = state;

    if (state.min < state.max) {
      this.isInitialized = true;
    }
  }

  componentDidUpdate() {
    const { filterData, category, priceMin, priceMax } = this.props;
    if (!this.isInitialized && filterData!.enable.enablePriceRange) {
      if (category && priceMin && priceMax) {
        const state = { min: parseFloat(priceMin), max: parseFloat(priceMax) };
        this.setState({ ...state });
        if (state.min < state.max || state.max > 0) {
          this.isInitialized = true;
        }
      }
    }
  }

  handleClear = () => {
    // group value
    const { category, priceMin, priceMax } = this.props;

    let state: FilterState = { ...this.defaultState, options: {} };

    if (category && category.proCnt > 0) {
      state = { ...state, min: parseFloat(priceMin), max: parseFloat(priceMax) };
    }
    this.setState({ ...state });
  };

  // state value will be updated in onSliderChange when category changed
  handleApply = () => {
    // group value
    const { min, max, options } = this.state;

    const filterData = {
      priceFrom: min,
      priceTo: max,
      ...options,
    };

    // put value in state, and then load product
    this.props.applyFilter(filterData);

    // then call parent props
    this.props.onFilterApplied({
      priceFrom: min,
      priceTo: max,
      ...options,
    });
  };

  onSliderChange = ({ min, max }: { min: number; max: number }) => {
    if ((utils.isNumber(min) && min !== this.state.min) || (utils.isNumber(max) && max !== this.state.max)) {
      this.setState({ min, max });
    }
  };

  onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    let newValue = parseFloat(e.currentTarget.value);
    if (!utils.isNumber(newValue)) {
      newValue = 0;
    }

    if (name === 'min') {
      this.setState({ min: newValue });
    } else if (name === 'max') {
      this.setState({ max: newValue });
    }
  };

  onBlurChange = () => {
    const { priceMin, priceMax } = this.props;
    const minLimit = parseFloat(priceMin || '0');
    const maxLimit = parseFloat(priceMax || '0');
    const { min, max } = this.state;

    let minCheckValue = Math.max(minLimit, Math.min(min, maxLimit));
    let maxCheckValue = Math.min(maxLimit, Math.max(max, minLimit));

    if (max < minCheckValue) {
      minCheckValue = 0;
    }
    if (min > maxCheckValue) {
      maxCheckValue = maxLimit;
    }

    if (min !== minCheckValue || max !== maxCheckValue) {
      this.setState({ min: minCheckValue, max: maxCheckValue });
    }
  };

  handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, groupName: string) => {
    const name = e.currentTarget.name;
    let isChecked: any = e.currentTarget.checked;
    const { options } = this.state;
    let newOptions = { ...options };

    if (utils.isUndefined(options) || !options!.hasOwnProperty(groupName)) {
      newOptions[groupName] = [];
    }

    if (isChecked) {
      newOptions[groupName].push(name);
    } else {
      newOptions[groupName] = newOptions[groupName].filter((item) => item !== name);
    }

    this.setState({ options: newOptions });
  };

  handleDateChange = (value: any, groupName: string) => {
    const { options } = this.state;
    let newOptions = { ...options };
    if (utils.isUndefined(options) || !options!.hasOwnProperty(groupName)) {
      newOptions[groupName] = [];
    }

    if (value === null) {
      newOptions[groupName] = [];
    } else {
      newOptions[groupName] = [value];
    }
    this.setState({ options: newOptions });
  };

  onDateBlur = (key: string) => {
    const date = this.getDateValue(key);
    const isValid = utils.isValidDate(date, true);
    if (!isValid) {
      this.handleDateChange(null, key);
    }
  };

  getIsChecked(groupName: string, fieldName: string) {
    const { options } = this.state;
    if (options && options.hasOwnProperty(groupName)) {
      return options[groupName].includes(fieldName);
    }
    return false;
  }

  getDateValue(groupName: string) {
    const { options } = this.state;
  
    if (options && options.hasOwnProperty(groupName) && options[groupName].length === 1) {
      return options[groupName][0];
    }
    return null;
  }

  getCheckedCount(groupName: string) {
    const { options } = this.state;
    if (options && options.hasOwnProperty(groupName)) {
      return options[groupName].length;
    }
    return 0;
  }

  renderCategories = () => {
    const { categories, categoryCode, t } = this.props;
    return categories.map((v, i) => {
      let categoryName = getCategoryName(v);
      if (v.code === 'All') {
        categoryName = t('ce01_pmpcore.react.all');
      }
      return (
        <Link
          key={v.code}
          to={`/categories/${v.code}`}
          className={`btn btn-sm rounded-pill me-2 mb-2 btn-${v.code === categoryCode ? 'main' : 'outline-main'}`}
          replace>
          {`${categoryName} (${v.proCnt})`}
        </Link>
      );
    });
  };

  renderCheckboxFilter = (filter: FieldInfo) => {
    const { key, keyLabel, value, valueLabel } = filter;

    const checkedCount = this.getCheckedCount(key);
    const options = value
      .map((v, i) => {
        if (v === '' || (key === 'countryOfOrigin' && v === '0')) {
          return null;
        }
        const label = valueLabel[v];
        return (
          <Form.Check
            type="checkbox"
            key={`${key}_${v}${this.props.isMobile ? '_m' : ''}`}
            id={`${key}_${v}${this.props.isMobile ? '_m' : ''}`}
            label={geti18nValue(label)}
            name={v}
            checked={this.getIsChecked(key, v)}
            onChange={(e) => this.handleCheckboxChange(e, key)}
          />
        );
      })
      .filter((option) => option !== null);

    return (
      <Accordion.Item key={key} eventKey={key}>
        <Accordion.Header>{`${geti18nValue(keyLabel)} (${checkedCount > 0 ? checkedCount + '/' : ''}${
          options.length
        })`}</Accordion.Header>
        <Accordion.Body>
          <div className="d-flex flex-column">{options}</div>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  renderDateFilter = (filter: FieldInfo) => {
    const { t } = this.props;
    const { key, keyLabel } = filter;
    const date = this.getDateValue(key);
    let dateText = '';
    const isValid = utils.isValidDate(date);
    if (isValid) {
      dateText = t('{date, short}', { date: new Date(date) });
    }

    const input = (
      <DatePicker
        value={date}
        inputFormat="yyyy-MM-dd"
        onChange={(value: any) => this.handleDateChange(value, key)}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <div className="d-flex align-items-end flex-column date-picker">
            <Form.Control name={key} ref={inputRef} {...inputProps} onBlur={(e) => this.onDateBlur(key)} />
            {InputProps?.endAdornment}
          </div>
        )}
      />
    );

    return (
      <Accordion.Item key={key} eventKey={key}>
        <Accordion.Header>{`${geti18nValue(keyLabel)}${
          dateText.length > 0 ? '(' + dateText + ')' : ''
        }`}</Accordion.Header>
        <Accordion.Body>
          <div className="d-flex flex-column">{input}</div>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  renderOptionalFilter = () => {
    if (utils.isUndefined(this.props.filterData?.info)) {
      return null;
    }

    const subFilters = Object.values(this.props.filterData!.info);
    const enabledFilters = subFilters.filter((field) => field.enabled === true);

    return (
      <Accordion alwaysOpen>
        {enabledFilters.map((filter) => {
          if (filter.type === 'date') {
            return this.renderDateFilter(filter);
          }

          return this.renderCheckboxFilter(filter);
        })}
      </Accordion>
    );
  };

  renderPriceRange = () => {
    const { t, filterData, isSameAsBaseCurrency, baseCurrencyCode, priceMin, priceMax } = this.props;
    const minLimit = parseFloat(priceMin) || 0;
    const maxLimit = parseFloat(priceMax) || 0;
    const { min, max } = this.state;
    if (filterData!.enable.enablePriceRange) {
      return (
        <ListGroup.Item>
          <h6 className="mt-1 mb-2">
            {t('ce01_pmpcore.react.priceRange')} {`(${baseCurrencyCode})`}
          </h6>
          <div className="d-grid d-block mb-3">
            <MultiRangeSlider
              min={minLimit}
              max={maxLimit}
              values={{ min, max }}
              onChange={this.onSliderChange}
              key={`range_${minLimit}_${maxLimit}`}
            />
            <div className="form-floating mb-2">
              <input
                type="text"
                name="min"
                className="form-control"
                placeholder="Min"
                value={min}
                onChange={(e) => this.onInputChange(e)}
                onBlur={this.onBlurChange}
              />
              <label htmlFor="floatingInput">{t('ce01_pmpcore.kopapro.react.minPrice')}</label>
            </div>
            <div className="form-floating mb-2">
              <input
                type="text"
                name="max"
                className="form-control"
                value={max}
                placeholder="Max"
                onChange={(e) => this.onInputChange(e)}
                onBlur={this.onBlurChange}
              />
              <label htmlFor="floatingInput">{t('ce01_pmpcore.kopapro.react.maxPrice')}</label>
            </div>
          </div>
          {!isSameAsBaseCurrency &&
            `(${formatAmount(min, Currency.SELECTED)} ${t('ce01_pmpcore.react.to').toLowerCase()} ${formatAmount(
              max,
              Currency.SELECTED
            )})`}
        </ListGroup.Item>
      );
    }
    return null;
  };

  render() {
    const { t, filterData, category, hideCategory, isFilterEnabled } = this.props;
    if (!category) {
      return null;
    }

    return (
      <ListGroup variant="flush" className="filters">
        {!hideCategory && (
          <Accordion alwaysOpen>
            <Accordion.Item eventKey="category">
              <Accordion.Header>{t('ce01_pmpcore.react.category')}</Accordion.Header>
              <Accordion.Body>
                <div
                  className={classnames('d-flex flex-wrap my-2 flex-column', {
                    'flex-column': config.categoryPerRow,
                  })}>
                  {this.renderCategories()}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}

        {filterData && isFilterEnabled && (
          <>
            {this.renderOptionalFilter()}
            {this.renderPriceRange()}
            <ListGroup.Item>
              <button onClick={this.handleClear} className="w-100 btn btn-outline-main mb-2">
                {t('ce01_pmpcore.react.clear')}
              </button>
              <button onClick={this.handleApply} className="w-100 btn btn-main">
                {t('ce01_pmpcore.react.apply')}
              </button>
            </ListGroup.Item>
          </>
        )}
      </ListGroup>
    );
  }
}

export default FilterMenu;
