import { ClientResponse } from '@kopapro-redux/types/api';
import axios, { AxiosRequestConfig } from 'axios';
import sha1 from 'crypto-js/sha1';
import hex from 'crypto-js/enc-hex';
import { Product, ProductFilter, ProductGroup } from '@kopapro-redux/types/products';
import { ShopLayout } from '@kopapro-redux/types/shop';
import { QueryList } from '@kopapro-redux/types/general';
import { ProductCategory } from '@kopapro-redux/types/productCategory';
import { getStorageItem } from '@kopapro-redux/utils/cookies';
import { KOPAPRO } from '@kopapro-redux/utils/constant';
import { DeliveryMethod } from '@kopapro-redux/types/deliveryMethod';
import { PaymentMethod, StoredValue } from '@kopapro-redux/types/paymentMethod';
import { InputValue, InputValues } from '@kopapro-redux/types/componentSetting';
import { CheckoutDeliveryFormData, CheckoutExtraFormData, ConfirmOrderParam } from '@kopapro-redux/types/checkout';
import { BlogCategory, BlogDetail, GetBlogListPayload } from '@kopapro-redux/types/blog';
import { MemberViewMode, QueryFormData, QueryUserInfo, UpdateInfoFormData } from '@kopapro-redux/types/member';
import { CartPromoInfos, GiftRedemptionDataKey } from '@kopapro-redux/types/cart';


import { store } from '@kopapro-redux/store';
import { getCurrentLang } from '@kopapro-redux/selectors/entities/user';
import { ShippingRegion } from '@kopapro-redux/types/shippingRegion';
import { BonusPointsSetup } from '@kopapro-redux/types/bonusPoints';
class KopaproApiClass {
  url = '';
  urlVersion = ''; // 'api/v1', not used in kopapro, keep for partice only

  getUrl() {
    return this.url;
  }

  setUrl(url: string) {
    this.url = url;
  }

  // Route URLs
  getBaseRoute() {
    if (this.getUrl().length === 0) {
      if (window.configSetter) {
        // if (process.env.NODE_ENV === 'development' || window.configSetter.mode === 'development') {
        //   this.setUrl(window.configSetter.proxy_server_url);
        // } else {
        //   this.setUrl(`${window.location.origin}/api`);
        // }
        this.setUrl(window.configSetter.proxy_server_url);
      }
    }

    return `${this.url}${this.urlVersion}`;
  }

  // PortalUrl
  getPortalUrl() {
    if (window.configSetter) {
      return window.configSetter.portalUrl;
    }
    return '';
  }

  // mode
  getMode() {
    if (window.configSetter) {
      return window.configSetter.mode;
    }
    return 'production';
  }

  getUsersRoute() {
    return `${this.getBaseRoute()}/users`;
  }

  getShopRoute() {
    return `${this.getBaseRoute()}/onlineshop`;
  }

  getBlogRoute() {
    return `${this.getShopRoute()}/blog`;
  }

  getOrderRoute() {
    return `${this.getShopRoute()}/order`;
  }

  // API Services
  checkPortalUpdate = () => {
    return this.doFetch<any>(`${this.getShopRoute()}/users/checkUpdate`, {
      method: 'get',
    });
  };

  getMetaLayout = () => {
    return this.doFetch<ShopLayout>(`${this.getShopRoute()}/getMetaLayout`, {
      method: 'get',
    });
  };

  getMetaCompSetting = () => {
    return this.doFetch<ShopLayout>(`${this.getShopRoute()}/getMetaCompSetting`, {
      method: 'get',
    });
  };

  getMetaSetup = () => {
    return this.doFetch<any>(`${this.getShopRoute()}/getMetaSetup`, {
      method: 'get',
    });
  };

  getCurrency = () => {
    return this.doFetch<any>(`${this.getShopRoute()}/currency`, {
      method: 'get',
    });
  };

  // get detail components

  getBanners = () => {
    return this.doFetch<any>(`${this.getShopRoute()}/banner`, {
      method: 'get',
    });
  };

  // M18 Options

  getM18ComboList = (pattern: string) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/resources/getComboList`, {
      method: 'get',
      params: { pattern },
    });
  };

  getM18LookupList = (pattern: string) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/resources/getLookupList`, {
      method: 'get',
      params: { pattern },
    });
  };

  // Proxy Server Temp Files

  uploadTempImage = (data: FormData) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/temp/uploadImage`, {
      method: 'post',
      data: data,
    });
  };

  // Shop Users

  resetUserPassword = (token: string, password: string, confirmPassword: string) => {
    const hash = sha1(password);
    const encodePassword = hash.toString(hex);
    const hash2 = sha1(confirmPassword);
    const encodeConfirmPassword = hash2.toString(hex);

    return this.doFetch<any>(`${this.getShopRoute()}/users/resetPassword`, {
      method: 'post',
      data: {
        token,
        password: encodePassword,
        confirmPassword: encodeConfirmPassword,
      },
    });
  };

  changeUserPassword = (newPassword: string, oldPassword: string, phoneNumber: string, userId: number) => {
    const hash = sha1(newPassword);
    const encodeNewPassword = hash.toString(hex);
    const hash2 = sha1(oldPassword);
    const encodeOldPassword = hash2.toString(hex);

    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/home/changePassword`, {
      method: 'post',
      data: {
        newPassword: encodeNewPassword,
        oldPassword: encodeOldPassword,
        phoneNumber,
        userId,
      },
    });
  };

  getUserInfo = (viewId: number, viewMode: MemberViewMode) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/home/readRequest`, {
      method: 'post',
      data: {
        viewId,
        viewMode,
      },
    });
  };

  updateMemberInfo = (
    phoneNumber: string,
    formInfo: UpdateInfoFormData,
    viewMode: MemberViewMode,
    imageList: string[]
  ) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/home/submitRequest`, {
      method: 'post',
      data: {
        phoneNumber,
        formInfo,
        viewMode,
        imageList,
      },
    });
  };

  register = (inputs: InputValues, password: string, acceptMarket: boolean, data: {}, params: {}) => {
    const hash = sha1(password);
    const encodePassword = hash.toString(hex);

    let passwordInput: InputValue = {
      inputId: 'password',
      value: encodePassword,
    };
    inputs = { ...inputs, password: passwordInput };

    // input data
    data = {
      ...data,
      accpetMarket: acceptMarket,
      inputs,
      viewId: 'register',
    };

    return this.doFetch<any>(`${this.getShopRoute()}/users/register`, { method: 'post', data: data, params: params });
  };

  requestSMS = (phoneNumber: string) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/register/getSMSId`, {
      method: 'post',
      data: {
        phoneNumber,
      },
    });
  };

  verifySMS = (paramId: string, smsId: string, smsVeri: string) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/register/verifySMSRequest`, {
      method: 'post',
      data: {
        paramId,
        smsId,
        smsVeri,
      },
    });
  };

  getLoginInfo = () => {
    return this.doFetch<any>(`${this.getShopRoute()}/users/loginInfo`, {
      method: 'get',
      data: {},
    });
  };

  login = (inputs: InputValues, password: string, data: {}, params: {}) => {
    const hash = sha1(password);
    const encodePassword = hash.toString(hex);

    let passwordInput: InputValue = {
      inputId: 'password',
      value: encodePassword,
    };
    inputs = { ...inputs, password: passwordInput };

    // input data
    data = {
      ...data,
      inputs,
      viewId: 'login',
    };

    return this.doFetch<any>(`${this.getShopRoute()}/users/login`, { method: 'post', data: data, params: params });
  };

  autoLogin = (sessionId: string) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/autoLogin`, {
      method: 'post',
      data: {
        sessionID: sessionId,
      },
    });
  };

  logout = (sessionId: string) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/logout`, {
      method: 'post',
      data: {
        sessionID: sessionId,
      },
    });
  };

  refreshCaptcha = (captchaID: string) => {
    if (captchaID === '') {
      return this.doFetch<any>(`${this.getUsersRoute()}/initCaptcha`, {
        method: 'get',
        data: {},
      });
    }
    return this.doFetch<any>(`${this.getUsersRoute()}/refreshCaptcha`, {
      method: 'get',
      params: { captchaID },
    });
  };

  forgotPassword = (email: string) => {
    return this.doFetch<any>(`${this.getShopRoute()}/users/forgetPassword`, {
      method: 'post',
      data: {
        identity: email,
      },
    });
  };

  sendContactUs = (compId: string, data: any) => {
    return this.doFetch<any>(`${this.getShopRoute()}/users/submitContactUsForm`, {
      method: 'post',
      params: { compId },
      data,
    });
  };

  subscribeNewsletter = (email: string) => {
    return this.doFetch<any>(`${this.getShopRoute()}/users/subscribedNews`, {
      method: 'get',
      params: { email },
    });
  };

  // Products

  getProductCategories = () => {
    return this.doFetch<QueryList<ProductCategory>>(`${this.getShopRoute()}/proCategory`, {
      method: 'get',
    });
  };

  getProduct = (gpId = 0, proId = 0, memTypeId = 0, seoUrl = '') => {
    return this.doFetch<Product>(`${this.getShopRoute()}/proDetail`, {
      method: 'get',
      params: { gpId, proId, memTypeId, seoUrl },
    });
  };

  getProductByCode = (proCode: string, gpCode: string, seoUrl = '', memTypeId = 0) => {
    return this.doFetch<any>(`${this.getShopRoute()}/proDetail`, {
      method: 'get',
      params: { proCode, gpCode, memTypeId, seoUrl },
    });
  };

  getRelatedItems = (proGps: number[] = [], memTypeId = 0, startPoint = 0, dataCount = KOPAPRO.PAGE_SIZE) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/proListSearch`, {
      method: 'get',
      params: { proGps, startPoint, dataCount, memTypeId },
    });
  };

  getPackageProducts = (pros: number[] = [], memTypeId = 0, startPoint = 0, dataCount = KOPAPRO.PAGE_SIZE) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/proListSearch`, {
      method: 'get',
      params: { pros, startPoint, dataCount, memTypeId },
    });
  };

  getProductByCategory = (
    proCat = 0,
    sortBy = 'pop',
    startPoint = 0,
    dataCount = KOPAPRO.PAGE_SIZE,
    memTypeId = 0,
    filter?: ProductFilter,
    keyword = ''
  ) => {
    let params: any = { proCat, sortBy, memTypeId, startPoint, dataCount, keyword };
    if (filter) {
      const { priceFrom, priceTo, ...rest } = filter;
      params.priceFrom = priceFrom;
      params.priceTo = priceTo;
      params.navAttrs = rest;
    }

    return this.doFetch<any[]>(`${this.getShopRoute()}/proListSearch`, {
      method: 'get',
      params: params,
    });
  };

  searchProducts = (keyword: string, memTypeId = 0, startPoint = 0, dataCount = KOPAPRO.PAGE_SIZE) => {
    let params: any = { keyword, memTypeId, startPoint, dataCount };

    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/proListSearch`, {
      method: 'get',
      params: params,
    });
  };

  checkProductInWishlist = (memberId: number, proId: number) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/checkProWishList`, {
      method: 'get',
      params: {
        memId: memberId,
        proId,
      },
    });
  };

  addProductToWishlist = (memberId: number, proId: number, noticeStock: boolean = false) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/addProWishList`, {
      method: 'get',
      params: {
        memId: memberId,
        proId,
        noticeStk: noticeStock,
      },
    });
  };

  removeProductFromWishlist = (memberId: number, proId: number) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/removeProWishList`, {
      method: 'get',
      params: {
        memId: memberId,
        proId,
      },
    });
  };

  loadProductComment = (proIdList: number[]) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/loadPosComment`, {
      method: 'post',
      data: {
        proIdList: proIdList,
      },
    });
  };

  getProductComment = (proIdList: number[], queryId: string, startPoint: number, dataCount: number) => {
    return this.doFetch<QueryList<ProductGroup>>(`${this.getShopRoute()}/getPosComments`, {
      method: 'post',
      data: {
        proIdList,
      },
      params: {
        queryId,
        startPoint,
        dataCount,
      },
    });
  };

  // delivery methods

  getDeliveryMethods = () => {
    return this.doFetch<QueryList<DeliveryMethod>>(`${this.getShopRoute()}/shipmethod`, {
      method: 'get',
    });
  };

  // payment methods

  getPaymentMethods = () => {
    return this.doFetch<QueryList<PaymentMethod>>(`${this.getShopRoute()}/paymethod`, {
      method: 'get',
    });
  };

  // shipping region

  getShippingRegion = () => {
    return this.doFetch<QueryList<ShippingRegion>>(`${this.getShopRoute()}/shipregion`, {
      method: 'get',
    });
  };

  // bonus points
  getBonusPointsSetup = () => {
    return this.doFetch<QueryList<BonusPointsSetup>>(`${this.getShopRoute()}/bonusPoints`, {
      method: 'get',
    });
  };

  // same us readOrder?
  // Cart

  retrieveCartItem = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/refreshOrder`, {
      method: 'post',
      data: {
        orderId,
      },
    });
  };

  modifyCartItem = (orderId: string, proId: number, changeQty: number) => {
    let path = changeQty > 0 ? 'addPro' : 'deductPro';

    return this.doFetch<any>(`${this.getOrderRoute()}/${path}`, {
      method: 'post',
      data: {
        orderId,
        proId,
        qty: Math.abs(changeQty),
      },
    });
  };

  removeProductFromCart = (orderId: string, proId: number, changeQty = 0) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/deletePro`, {
      method: 'post',
      data: {
        orderId,
        proId,
        qty: Math.abs(changeQty),
      },
    });
  };

  selectGiftRedemptionFromCart = (orderId: string, proId: number, promoInfos: CartPromoInfos[], isSelect: boolean) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/selectGift`, {
      method: 'post',
      data: {
        orderId,
        proId,
        promoInfos,
        isSelect,
      },
    });
  };

  applyRedemptionToCart = (orderId: string, redeemList: GiftRedemptionDataKey[]) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/applyRedemption`, {
      method: 'post',
      params: { orderId },
      data: {
        orderId,
        redeemList,
      },
    });
  };

  applyGiftToCart = (orderId: string, giftList: GiftRedemptionDataKey[]) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/applyGift`, {
      method: 'post',
      params: { orderId },
      data: {
        orderId,
        giftList,
      },
    });
  };

  checkCart = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/checkOrder`, {
      method: 'post',
      data: {
        orderId,
      },
    });
  };

  getPromotionAlert = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/promoAlert`, {
      method: 'post',
      data: {
        orderId,
      },
    });
  };

  getMemberTypeAlert = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/memberAlert`, {
      method: 'post',
      data: {
        orderId,
      },
    });
  };

  getRedemptionList = (orderId: string, proId: number = 0) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/redemptionList`, {
      method: 'get',
      params: {
        orderId,
        proId,
      },
    });
  };

  getGiftList = (orderId: string, proId: number = 0) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/giftList`, {
      method: 'get',
      params: {
        orderId,
        proId,
      },
    });
  };

  // check-out
  addDiscountCodesToOrder = (orderId: string, discCodes: string[]) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/addDiscCode`, {
      method: 'post',
      data: {
        orderId,
        discCodes,
      },
    });
  };

  addMemberToOrder = (orderId: string, memberId?: number, email?: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/addMember`, {
      method: 'post',
      data: {
        orderId,
        memberId,
        email,
      },
    });
  };

  addDeliveryToOrder = (orderId: string, delivery: CheckoutDeliveryFormData) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/addDelivery`, {
      method: 'post',
      data: {
        orderId,
        delivery,
      },
    });
  };

  checkDeliveryCharge = (orderId: string, shipId: number, shipRegionId: number) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/deliveryCharge`, {
      method: 'get',
      params: { orderId, shipId, shipRegionId },
    });
  };

  addPaymentToOrder = (orderId: string, paymentId: number) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/addPayment`, {
      method: 'post',
      data: {
        orderId,
        paymentId,
      },
    });
  };

  checkPaymentSurcharge = (orderId: string, paymentId: number) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/paymentSurcharge`, {
      method: 'get',
      params: { orderId, paymentId },
    });
  };

  loadCoupon = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/svDetail`, {
      method: 'get',
      params: { orderId },
    });
  };

  applyCoupon = (orderId: string, storedValues: StoredValue[]) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/applySV`, {
      method: 'post',
      params: { orderId },
      data: {
        storeValues: storedValues,
      },
    });
  };

  loadCouponVouchers = (svId: number, orderBy: string = 'svfCode', orderByAsc: boolean = false) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/svList`, {
      method: 'get',
      params: { svId, orderBy, orderByAsc },
    });
  };

  loadCouponVoucherLedger = (svId: number, svfId: number, startDate: string, endDate: string = '9999-12-31') => {
    return this.doFetch<any>(`${this.getOrderRoute()}/svLedger`, {
      method: 'get',
      params: { svId, svfId, startDate, endDate },
    });
  };

  loadBonusPointsLedger = (svId: number, startDate: string, endDate: string = '9999-12-31') => {
    return this.doFetch<any>(`${this.getOrderRoute()}/bpLedger`, {
      method: 'get',
      params: { svId, startDate, endDate },
    });
  };

  retrieveOrderExtra = (orderId: string, viewId: number) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/loadExtra`, {
      method: 'post',
      data: {
        orderId,
        viewId,
      },
    });
  };

  saveOrderExtra = (orderId: string, formData: CheckoutExtraFormData, imageList: string[] = []) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/addExtra`, {
      method: 'post',
      data: {
        orderId: orderId,
        formInfo: formData,
        viewMode: {
          mode: 'create',
          entityId: 0,
        },
        imageList,
      },
    });
  };

  confirmOrder = (orderId: string, saveParam: ConfirmOrderParam) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/saveOrder`, {
      method: 'post',
      data: {
        orderId,
        saveParam,
      },
    });
  };

  cancelOrder = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/cancelOrder`, {
      method: 'post',
      data: {
        orderId,
      },
    });
  };

  requestEmail = (email: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/veriCode`, {
      method: 'get',
      params: {
        email,
      },
    });
  };

  verifyEmail = (veriId: string, veriNum: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/veriAnswer`, {
      method: 'get',
      params: {
        veriId,
        veriNum,
      },
    });
  };

  processOnlinePayment = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/onlinePayment`, {
      method: 'post',
      params: {
        orderId,
      },
    });
  };

  approvePayPal = (orderId: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/saveOrder`, {
      method: 'post',
      data: {
        orderId,
      },
    });
  };

  applyProductComment = (orderId: string, proId: number, score: number, comment: string) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/addComment`, {
      method: 'post',
      data: {
        orderId,
        proId,
        score,
        comment,
      },
    });
  };

  getSinglePageOrder = (orderToken: string) => {
    return this.doFetch<any>(`${this.getShopRoute()}/singlePageOrder`, {
      method: 'get',
      params: {
        orderToken,
      },
    });
  };

  // blog
  getBlogDetail = (blogId: string) => {
    return this.doFetch<QueryList<BlogDetail>>(`${this.getBlogRoute()}/getDetail`, {
      method: 'get',
      params: {
        blogId,
      },
    });
  };

  getBlogList = (params: GetBlogListPayload) => {
    return this.doFetch<QueryList<BlogDetail>>(`${this.getBlogRoute()}/getList`, {
      method: 'get',
      params: {
        ...params,
      },
    });
  };

  getBlogCategories = () => {
    return this.doFetch<QueryList<BlogCategory>>(`${this.getBlogRoute()}/category`, {
      method: 'get',
    });
  };

  // member center
  queryMemberRequest = (formData: QueryFormData, userInfo?: QueryUserInfo) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/home/queryRequest`, {
      method: 'post',
      data: {
        formInfo: formData,
        userInfo,
      },
    });
  };

  getMemberQueryData = (viewId: string | number, queryId: string, startPoint: number, dataCount: number) => {
    return this.doFetch<any>(`${this.getUsersRoute()}/pmp/home/getQueryResults`, {
      method: 'post',
      params: {
        viewId,
        queryId,
        startPoint,
        dataCount,
      },
    });
  };

  // Custom Servlet
  callServlet = (orderId: string, className: string, methodName: string, classArgs: any) => {
    return this.doFetch<any>(`${this.getOrderRoute()}/kppServlet`, {
      method: 'post',
      params: {
        orderId,
      },
      data: {
        className,
        methodName,
        classArgs,
      },
    });
  };

  // Send Request

  getOptions = (config: AxiosRequestConfig) => {
    // append additional options on here`

    const state = store.getState();
    const language = getCurrentLang(state);
    if (!config.params) {
      config.params = {};
    }

    config.params.portalUrl = this.getPortalUrl();
    config.params.language = language || getStorageItem('lang');
    config.params.version = getStorageItem('version');
    config.params.sessionKey = getStorageItem('sessionKey');
    const trafficSource = getStorageItem('trafficSource');
    const referLink = getStorageItem('referLink');

    config.headers = {
      ...config.headers,
      Authorization: getStorageItem('sessionId'),
      TrafficSource: trafficSource,
      ReferLink: referLink,
    };

    return config;
  };

  // Client
  doFetch = async <T>(url: string, options: AxiosRequestConfig): Promise<T> => {
    const { data } = await this.doFetchWithResponse<T>(url, options);

    return data;
  };

  doFetchWithResponse = async <T>(url: string, options: AxiosRequestConfig): Promise<ClientResponse<T>> => {
    // append portalUrl, bersion, language, session, memTypeId, seoUrl and etc
    let requestConfig = this.getOptions(options);
    options.url = url;

    const response = await axios(requestConfig);
    let data;
    try {
      data = response.data;
    } catch (err) {
      // throw new ClientError(this.getUrl(), {
      //     message: 'Received invalid response from the server.',
      //     intl: {
      //         id: 'mobile.request.invalid_response',
      //         defaultMessage: 'Received invalid response from the server.',
      //     },
      //     url,
      // });
    }

    // assume success
    if (response.status >= 200 && response.status <= 299) {
      if (response.headers['portal-status'] === 'expire') {
        throw new Error('kopapro_expired');
      }

      return {
        response,
        headers: response.headers,
        data,
      };
    }
    throw response;
    // const msg = data.message || '';

    // if (this.logToConsole) {
    //     console.error(msg); // eslint-disable-line no-console
    // }

    // throw new ClientError(this.getUrl(), {
    //     message: msg,
    //     server_error_id: data.id,
    //     status_code: data.status_code,
    //     url,
    // });
  };
}

const KopaproApi = new KopaproApiClass();
export default KopaproApi;
