import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { RootState } from '@kopapro-redux/store';
import Header from '@kopapro/components/header/header';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withLocation, withNavigation } from '@kopapro/components/commons/router-hoc';
import { ShopLayoutStyle } from '@kopapro-redux/types/shop';
import { getShopName, getShopInfo, getNavbar, getMetaBasicSetup } from '@kopapro-redux/selectors/entities/shop';
import { getNumberOfItemsInCart } from '@kopapro-redux/selectors/entities/cart';
import { ComponentShopInfo, GridLayout } from '@kopapro-redux/types/componentSetting';
import { getCurrentLang, getCurrentUserInfo, userLoggedIn } from '@kopapro-redux/selectors/entities/user';
import { UserInfo } from '@kopapro-redux/types/user';
import { logout } from '@kopapro-redux/actions/user';
import withConfigurable from '@kopapro/components/preview/configurable';
import { M18RnMeta } from '@kopapro-redux/utils/constant';
import { I18nDictionary } from '@kopapro-redux/types/utilities';

interface IProps extends WithTranslation {
  location: any;
  navigate: any;
  backgroundColor: string;
}

interface StateProps {
  checkShopCart: boolean;
  layout: ShopLayoutStyle | undefined;
  companyName: I18nDictionary | string;
  hideComponents: boolean;
  backgroundImage: string;
  mobileBackgroundImage: string;
  shopInfo: ComponentShopInfo | undefined;
  lang: string;
  numberOfCartItems: number;
  userInfo: UserInfo;
  userLoggedIn: boolean;
  // grid layout
  desktopGridLayout?: GridLayout;
  mobileGridLayout?: GridLayout;
}

interface DispatchProps {
  logout: typeof logout;
}

const mapStateToProps: MapStateToProps<StateProps, IProps, RootState> = (state: RootState, props: IProps) => {
  const shopInfo = getShopInfo(state);

  return {
    checkShopCart: getMetaBasicSetup(state).checkShopCart,
    layout: getNavbar(state)?.layout,
    companyName: getShopName(state),
    shopInfo, // use {logo, headBanner, }
    backgroundImage: shopInfo?.headBanner || '',
    mobileBackgroundImage: shopInfo?.mobileHeadBanner || '',
    hideComponents: false, // true for manager checkin only
    lang: getCurrentLang(state),
    numberOfCartItems: getNumberOfItemsInCart(state),
    userInfo: getCurrentUserInfo(state),
    userLoggedIn: userLoggedIn(state),
    desktopGridLayout: shopInfo?.enablePageHeaderGridLayout ? shopInfo.pageHeaderGridLayout : undefined,
    mobileGridLayout: shopInfo?.enableMobileHeaderGridLayout ? shopInfo.mobileHeaderGridLayout : undefined,
    // desktopGridLayout: undefined,
    // mobileGridLayout: undefined,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, IProps> = {
  logout: logout,
};

const configurableProps = {
  m18CompId: M18RnMeta.SHOPINFO,
};

export type HeaderProps = IProps & StateProps & DispatchProps;
const component = withNavigation(withLocation(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header))));
export default withConfigurable(component, configurableProps);
