import { Component } from 'react';
import { AppControlProps } from '@kopapro/components/misc/appControl';
import htmlUtil from '@kopapro/utils/utils';
import { geti18nValue } from '@kopapro-redux/utils/m18';
export default class AppControl extends Component<AppControlProps> {
  componentDidMount(): void {
    window.addEventListener('beforeunload', this.checkCartHandler);
    this.setSEO();
  }

  componentDidUpdate = () => {
    this.setSEO();
  };

  setSEO = () => {
    const { seo, companyName } = this.props;
    let title = geti18nValue(seo.titleObj) || seo.title || companyName || '';
    let description = geti18nValue(seo.descriptionObj) || seo.description || '';
    let keyword = seo.seoKeywords || '';
    htmlUtil.setDocumentMeta(title, description, keyword);
  };

  componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.checkCartHandler);
  }

  checkCartHandler = (event: BeforeUnloadEvent) => {
    const { numberOfCartItems, checkShopCart } = this.props;
    if (checkShopCart && numberOfCartItems > 0) {
      const confirmationMessage = 'You have items in Shopping Cart. Confirm to leave?';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
    return null;
  };

  render() {
    return null;
  }
}
