import { getCompHeight } from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { ContactUsProps } from '@kopapro/components/shop/contactUs';
import utils from '@kopapro/utils/utils';
import ContactUsForm from '@kopapro/components/shop/contactUs/form';

export default class ContactUs extends Component<ContactUsProps> {
  render(): ReactNode {
    const { compId } = this.props;

    if (utils.isUndefined(this.props.content)) {
      return null;
    }
    const { t } = this.props;
    const { heightOption, udfHeight } = this.props.content!;

    // force auto
    const height = getCompHeight(heightOption, udfHeight);

    return (
      <div className="container">
        <div
          id={`contact-us-${compId}`}
          className={`contact-us row ignore-height-xs`}
          style={{ height, minHeight: 'fit-content' }}>
          <div className="col-12">
            <h5>{t('ce01_pmpcore.react.contactUs')}</h5>
          </div>

          <ContactUsForm {...this.props} />
        </div>
      </div>
    );
  }
}
