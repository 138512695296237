import { Link } from "react-router-dom";
import { HeaderProps } from "@kopapro/components/header";
import React, { Component, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import ShopImage from '@kopapro/components/commons/shopImage';
import { ShopName, Language, Currency, Minicart, Search } from '@kopapro/components/header/components';
import Sidebar from '@kopapro/components/header/components/sidebar';
import KppNavbar from '@kopapro/components/header/components/navbar';
import Announcement from '@kopapro/components/header/components/announcement';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import Button from 'react-bootstrap/Button';
import { geti18nValue, getCustomStyle, isHideMinicart, resolveImageURL } from '@kopapro/utils/m18';
import { headerConfig } from '@kopapro/utils/config';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import utils from '@kopapro-redux/utils/utils';
import { GridLayoutElement, GridLayoutRow } from '@kopapro-redux/types/componentSetting';
import DefaultModal from '@kopapro/components/commons/modals';

// for Drawer
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import classNames from 'classnames';

interface HeaderState {
  showInputbox: boolean;
  showCartDrawer: boolean;
  showMenuDrawer: boolean;
  showCheckCartModal: boolean;
}

class Header extends Component<HeaderProps, HeaderState> {
  lastScrollTop = 0;
  lastWindowWidth = 0;
  isShowShopName = headerConfig.isShowShopName;
  isFluid = headerConfig.isFluid;
  isFixedTop = headerConfig.isFixedTop;
  iconSize = headerConfig.iconSize as SizeProp;
  menuFloatRight = headerConfig.menuFloatRight;
  imageSize = headerConfig.imageSize;
  itemProps = {
    imageSize: this.imageSize,
    iconSize: this.iconSize,
  };

  gridDestopOverlayRef: React.RefObject<any>;
  gridScrollOverlayRef: React.RefObject<any>;
  gridMobileOverlayRef: React.RefObject<any>;
  defaultOverlayRef: React.RefObject<any>;

  constructor(props: HeaderProps) {
    super(props);

    this.gridDestopOverlayRef = React.createRef();
    this.gridMobileOverlayRef = React.createRef();
    this.defaultOverlayRef = React.createRef();
    this.gridScrollOverlayRef = React.createRef();
    this.state = {
      showInputbox: false,
      showCartDrawer: false,
      showMenuDrawer: false,
      showCheckCartModal: false,
    };
  }

  componentDidMount() {
    document.addEventListener('addToCart', this.handleAddToCart);
    document.addEventListener('scroll', this.handleScrollForNavBar);
    // add resize to change background image
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    document.removeEventListener('addToCart', this.handleAddToCart);
    document.removeEventListener('scroll', this.handleScrollForNavBar);
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: HeaderProps, prevState: HeaderState) {
    if (prevState.showCartDrawer !== this.state.showCartDrawer) {
      const app = document.getElementById('kopapro-app');
      if (this.state.showCartDrawer) {
        app!.className = app!.className + ' cart-opened';
      } else {
        app!.className = app!.className.replace(' cart-opened', '');
      }
    }
    //
  }

  handleResize = (e: any) => {
    const { shopInfo, desktopGridLayout, mobileGridLayout, backgroundImage, mobileBackgroundImage } = this.props;

    const isChangedtoLargerScreen = window.innerWidth >= 576 && this.lastWindowWidth < 576;
    const isChangedtoSmallScreen = window.innerWidth < 576 && this.lastWindowWidth >= 576;
    if (shopInfo && utils.isUndefined(desktopGridLayout) && utils.isUndefined(mobileGridLayout)) {
      const navbar = document.getElementsByClassName('default-navbar')[0] as HTMLElement;
      if (isChangedtoLargerScreen) {
        navbar.style.backgroundImage = `url(${resolveImageURL(backgroundImage)})`;
      } else if (isChangedtoSmallScreen) {
        navbar.style.backgroundImage = `url(${resolveImageURL(mobileBackgroundImage)})`;
      }
      this.lastWindowWidth = window.innerWidth;
    }
  };

  handleAddToCart = () => {
    [this.gridDestopOverlayRef, this.gridMobileOverlayRef, this.defaultOverlayRef, this.gridScrollOverlayRef].forEach(
      (overlayRef) => {
        if (overlayRef && overlayRef.current) {
          const minicart = overlayRef.current;
          minicart.className = minicart.className + ' wiggle';
          setInterval(function () {
            minicart.className = minicart.className.replace(' wiggle', '');
          }, 2000);
        }
      }
    );
    // this.setState({ showCartDrawer: true });
  };

  handleScrollForNavBar = () => {
    // const header = document.querySelector('.autohide') as HTMLElement | null;
    const navbar = document.querySelector('.autohide') as HTMLElement | null;
    const scrolledNavbar = document.querySelector('.scrolled-navbar') as HTMLElement | null;

    let navbarHeight = 0;
    if (navbar) {
      navbarHeight = navbar.offsetHeight;
    }

    if (navbar && scrolledNavbar) {
      let scrollTop = window.scrollY;
      if (scrollTop < this.lastScrollTop && scrollTop > navbarHeight) {
        // show navbar when scrollup
        scrolledNavbar.classList.remove('scrolled-down');
        scrolledNavbar.classList.add('scrolled-up');
      } else {
        scrolledNavbar.classList.remove('scrolled-up');
        scrolledNavbar.classList.add('scrolled-down');
      }

      this.lastScrollTop = scrollTop;
    }

    //minicart-drawer-wrapper
    const defaultNavbar = document.querySelector('.default-navbar') as HTMLElement | null;
    const minicart = document.querySelector('.minicart-drawer-wrapper .MuiPaper-root') as HTMLElement | null;
    const { desktopGridLayout } = this.props;
    if (minicart) {
      if (desktopGridLayout) {
        minicart.style.top = '0px';
        minicart.style.maxHeight = '100%';
      } else if (defaultNavbar) {
        if (window.scrollY >= defaultNavbar.offsetHeight) {
          minicart.style.top = '0px';
          minicart.style.maxHeight = '100%';
        } else {
          const diff = defaultNavbar.offsetHeight - window.scrollY;
          minicart.style.top = `${diff}px`;
          minicart.style.maxHeight = `calc(100% - ${diff}px)`;
        }
      }
    }
  };

  showCart = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showCartDrawer: true });
  };

  hideCart = () => {
    this.setState({ showCartDrawer: false });
  };

  clickAwayHandler = (e: MouseEvent | TouchEvent) => {
    if (this.state.showCartDrawer) {
      e.preventDefault();
      e.stopPropagation();
      this.hideCart();
    }
  };

  toggleDrawer = (open: boolean) => {
    this.setState({ showMenuDrawer: open, showCartDrawer: false });
  };

  getNabBarStyle = (needBackgroundColor = true) => {
    if (!this.props.layout || !this.props.layout.useUdfStyle) {
      return {};
    }
    const { useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle } = this.props.layout!;
    const { bannerPic, banner, bannerFontColor, bannerHeight } = this.props.layout!;
    let styleProps: any = { useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle };
    if (banner) {
      styleProps = {
        ...styleProps,
        backgroundImage: bannerPic,
        height: bannerHeight,
        fontColor: bannerFontColor || fontColor,
      };
    }

    return getCustomStyle(styleProps, needBackgroundColor);
  };

  getStyle = (needBackgroundColor = false) => {
    const { backgroundImage, layout } = this.props;
    const { useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle } = layout || {};
    const styleProps = { backgroundImage, useUdfStyle, backgroundColor, font, fontColor, fontSize, fontStyle };

    return getCustomStyle(styleProps, needBackgroundColor);
  };

  renderMiniCartDrawer = () => {
    const { numberOfCartItems } = this.props;
    const isNotEmptyCart = numberOfCartItems > 0;

    if (isNotEmptyCart) {
      return (
        <ClickAwayListener onClickAway={this.clickAwayHandler} mouseEvent="onMouseDown">
          <Drawer
            anchor="right"
            variant="persistent"
            open={this.state.showCartDrawer}
            className="minicart-drawer-wrapper"
            onClose={this.hideCart}>
            <Box role="presentation" className="minicart-drawer p-3 border border-start-0">
              <Minicart triggerHideCart={this.hideCart} />
            </Box>
          </Drawer>
        </ClickAwayListener>
      );
    }
    return null;
  };

  handleConfirmCheckCartModal = () => {
    this.setState({ showCheckCartModal: false });
    this.handleLogout();
  };

  handleCloseCheckCartModal = () => {
    this.setState({ showCheckCartModal: false });
  };

  onClickLogoutButton = () => {
    const { checkShopCart, numberOfCartItems } = this.props;
    if (checkShopCart && numberOfCartItems > 0) {
      this.setState({ showCheckCartModal: true });
    } else {
      this.handleLogout();
    }
  };

  handleLogout = () => {
    const { logout, navigate } = this.props;
    logout(null, function () {
      navigate('/login');
    });
  };

  renderMember = (fontStyle?: any, styles?: any) => {
    const { userLoggedIn, location } = this.props;
    const currentPathname = location.pathname + location.search;
    let redirect = '/account';
    if (!userLoggedIn) {
      redirect = '/login';
      if (currentPathname.startsWith('/login')) {
        redirect = currentPathname;
      } else if (!currentPathname.startsWith('/register') && currentPathname != '/') {
        redirect = `/login?redirect=${currentPathname}`;
      }
    }

    return (
      <React.Fragment key="member">
        <Nav.Link as={Link} to={redirect} className="d-flex align-self-center member" style={styles}>
          {this.getUserLogo(fontStyle)}
          <span className="member-name">{this.getUserDisplayName()}</span>
        </Nav.Link>
        {userLoggedIn && (
          <Button variant="link" onClick={() => this.onClickLogoutButton()} style={fontStyle}>
            <LogoutIcon />
          </Button>
        )}
      </React.Fragment>
    );
  };

  renderMiniCart = (cartRef: React.RefObject<any>, iconStyle?: any, styles?: any) => {
    const { t, numberOfCartItems } = this.props;
    const hasCartItem = numberOfCartItems > 0;
    if (isHideMinicart()) {
      return null;
    }
    return (
      <Nav.Link
        key="minicart"
        ref={cartRef}
        className={classNames('d-flex align-self-center position-relative animation minicart', {
          'non-empty': hasCartItem,
        })}
        style={styles}
        onClick={(e) => this.showCart(e)}>
        <ShoppingCartIcon sx={iconStyle} />
        {hasCartItem && (
          <span className="position-absolute top-25 start-100 translate-middle badge rounded-pill bg-danger">
            {numberOfCartItems > 99 ? '99+' : numberOfCartItems}
          </span>
        )}

        <Overlay
          container={cartRef}
          rootClose={true}
          onHide={() => {
            this.hideCart();
          }}
          show={!hasCartItem && this.state.showCartDrawer}
          target={cartRef.current}
          placement="bottom">
          {(props) => (
            <Popover {...props}>
              <Popover.Body className="text-center emptycart">
                <p className="m-0">{t('ce01_pmpcore.react.emtpyCart')}</p>
              </Popover.Body>
            </Popover>
          )}
        </Overlay>
      </Nav.Link>
    );
  };

  getUserDisplayName = (): string => {
    const { userInfo } = this.props;
    const name = geti18nValue(userInfo.name);
    if (utils.isNotEmpty(name)) {
      return name;
    }
    return userInfo.email;
  };

  getUserLogo = (style: any = {}): ReactNode => {
    const { userInfo, userLoggedIn } = this.props;

    if (!userLoggedIn || utils.isEmpty(userInfo.logo)) {
      return <PersonIcon sx={style} />;
    }

    return <ShopImage src={userInfo.logo} className="user-logo" alt="user logo" />;
  };

  getComponentByName = (element: GridLayoutElement | undefined, isMobile: boolean) => {
    if (!element) {
      return null;
    }
    const name = element.type;
    const { companyName, shopInfo } = this.props;
    const { font, width, height, marginLeft, marginRight, marginBottom, marginTop } = element;
    const fontStyle = getCustomStyle({ ...font, useUdfStyle: true }, true);
    const marginStyle = { marginLeft, marginRight, marginBottom, marginTop };
    const styles = { ...fontStyle, ...marginStyle };
    if (name === 'ShopName') {
      return (
        <Link to="/" key={name} className="pe-2 ">
          <ShopName name={geti18nValue(companyName)} style={{ ...styles, display: 'block' }} />
        </Link>
      );
    } else if (name === 'ShopLogo') {
      return (
        <Link to="/" key={name} className="overflow-hidden" style={{ maxHeight: 'inherit' }}>
          <ShopImage src={shopInfo!.logo} width={width} height={height} style={marginStyle} alt="shop logo" />
        </Link>
      );
    } else if (name === 'SearchTextBar') {
      const iconStyle = { color: element.font.iconColor, fontSize: width };
      return <Search key={name} iconStyle={iconStyle} style={marginStyle} />;
    } else if (name === 'CartIcon') {
      const iconStyle = { color: element.font.iconColor, fontSize: width };
      const ref = isMobile ? this.gridMobileOverlayRef : this.gridDestopOverlayRef;
      return this.renderMiniCart(ref, iconStyle, marginStyle);
    } else if (name === 'MemberSection') {
      return this.renderMember(fontStyle, styles);
    } else if (name === 'CurrencySelect') {
      return <Currency key={name} style={styles} fontStyle={fontStyle} />;
    } else if (name === 'LanguageSelect') {
      return <Language key={name} style={marginStyle} fontStyle={fontStyle} />;
    } else if (name === 'ShopMenu') {
      const toggleStyle = {
        marginLeft,
        marginRight,
        marginBottom,
        marginTop,
        color: font.iconColor,
        fontSize: width,
      };

      return (
        <KppNavbar key={name} toggleDrawer={this.toggleDrawer} showToggleOnly={isMobile} toggleStyle={toggleStyle} />
      );
    }

    return <span key={name}>{name}</span>;
  };

  renderElements = (elementNames: string[], isMobile = false) => {
    let { elements } = this.props.desktopGridLayout! || [];
    if (isMobile) {
      elements = this.props.mobileGridLayout!.elements;
    }

    return elementNames.map((name) => {
      const element = elements.find((element) => element.type === name);
      return this.getComponentByName(element, isMobile);
    });
  };

  getElementClassName = (elementDisplay: any) => {
    const { verticalAlign, horizontalAlign, display } = elementDisplay;

    let classes = `flex-${display} `;
    let horizontalAlignClass = '';
    let verticalAlignClass = '';

    horizontalAlignClass = `horizontal_${horizontalAlign}`;
    verticalAlignClass = `vertical_${verticalAlign}`;

    classes += `${horizontalAlignClass} ${verticalAlignClass}`;

    return classes;
  };

  renderGridCell = (cell: any, key: string, isMobile = false) => {
    const { elementDisplay, elements, flexWidth } = cell;
    return (
      <Nav
        key={`cell_${key}`}
        style={{ width: flexWidth * 100 + '%', maxHeight: 'inherit' }}
        className={` ${this.getElementClassName(elementDisplay)}`}>
        {this.renderElements(elements, isMobile)}
      </Nav>
    );
  };

  renderDekstopGridLayout(): ReactNode {
    const { desktopGridLayout, backgroundImage } = this.props;

    const backgroundStyle = getCustomStyle({ backgroundImage }, true);

    if (utils.isUndefined(desktopGridLayout)) {
      return null;
    }
    const { rows } = desktopGridLayout!;
    return (
      <>
        <Announcement className="d-none d-sm-block" />
        <header className="header autohide custom-layout d-none d-sm-block" style={backgroundStyle}>
          {rows.map((row: GridLayoutRow, index: number) => {
            const { height, cells, backgroundColor } = row;
            const isNavBarRow = cells.find((cell: any) => cell.elements.includes('ShopMenu')) !== undefined;
            const rowBackgroundStyle = getCustomStyle({ backgroundColor, backgroundImage: row.backgroundImage }, true);
            return (
              <Navbar
                key={`row_${index}`}
                className={`d-flex ${isNavBarRow ? ' py-0' : ''}`}
                style={{ height, maxHeight: height, ...rowBackgroundStyle }}>
                {this.renderGridCell(cells[0], index + '_0')}
                {this.renderGridCell(cells[1], index + '_1')}
                {this.renderGridCell(cells[2], index + '_2')}
              </Navbar>
            );
          })}
          <Navbar
            key={`row_scroll`}
            className="d-flex scrolled-navbar fixed-top scrolled-down"
            style={this.getNabBarStyle()}>
            <KppNavbar toggleDrawer={this.toggleDrawer} />
            <Nav key="member_and_cart" className={`justify-content-end flex-row flex-grow-1`}>
              {this.renderMember()}
              {this.renderMiniCart(this.gridScrollOverlayRef)}
            </Nav>
          </Navbar>
        </header>
      </>
    );
  }

  renderMobileGridLayout(): ReactNode {
    const { mobileGridLayout, mobileBackgroundImage } = this.props;
    if (utils.isUndefined(mobileGridLayout)) {
      return null;
    }
    const { rows } = mobileGridLayout!;
    const backgroundStyle = getCustomStyle({ backgroundImage: mobileBackgroundImage }, true);
    return (
      <>
        <Announcement className="d-block d-sm-none" />
        <header className="header autohide custom-layout d-block d-sm-none" style={backgroundStyle}>
          {rows.map((row: GridLayoutRow, index: number) => {
            const { height, cells, backgroundColor, backgroundImage } = row;

            const rowBackground = getCustomStyle({ useUdfStyle: true, backgroundImage, backgroundColor }, true);
            return (
              <Navbar key={`row_${index}`} className="d-flex " style={{ height, ...rowBackground }}>
                {this.renderGridCell(cells[0], index + '_0', true)}
                {this.renderGridCell(cells[1], index + '_1', true)}
                {this.renderGridCell(cells[2], index + '_2', true)}
              </Navbar>
            );
          })}
        </header>
      </>
    );
  }

  renderToggler = (forceShow = false) => {
    return (
      <button
        aria-controls="offcanvasNavbar-expand"
        type="button"
        aria-label="Toggle navigation"
        className={classNames('navbar-toggler ', { collapsed: !forceShow, 'force-display': forceShow })}
        onClick={(e) => this.toggleDrawer(true)}>
        <span className="navbar-toggler-icon"></span>
      </button>
    );
  };

  render(): ReactNode {
    const { t } = this.props;
    const { companyName, shopInfo, desktopGridLayout, mobileGridLayout, backgroundImage, mobileBackgroundImage } =
      this.props;

    if (!shopInfo) {
      return null;
    }
    this.lastWindowWidth = window.innerWidth;
    let backgroundStyle = getCustomStyle({ backgroundImage }, true);
    if (this.lastWindowWidth < 576) {
      backgroundStyle = getCustomStyle({ backgroundImage: mobileBackgroundImage }, true);
    }
    const { enableNameStyle, nameStyle, logo } = shopInfo;

    let defaultHeader: ReactNode = null;
    let defaultHeaderClass = '';
    if (utils.isDefined(mobileGridLayout)) {
      backgroundStyle = getCustomStyle({ backgroundImage }, true);
      defaultHeaderClass = 'd-none d-sm-block';
    }
    if (utils.isDefined(desktopGridLayout)) {
      backgroundStyle = getCustomStyle({ backgroundImage: mobileBackgroundImage }, true);
      defaultHeaderClass = 'd-block d-sm-none';
    }

    let fixedProps = {};
    if (this.isFixedTop) {
      fixedProps = { fixed: 'top' };
    }

    if (utils.isUndefined(desktopGridLayout) || utils.isUndefined(mobileGridLayout)) {
      defaultHeader = (
        <header className={`header kpp-header ${defaultHeaderClass}`}>
          <Announcement />
          <Navbar
            className="default-navbar border-bottom"
            bg="white"
            {...fixedProps}
            expand={'sm'}
            style={backgroundStyle}>
            <Container fluid={this.isFluid}>
              {!this.menuFloatRight && this.renderToggler()}
              <Link className="navbar-brand" to="/">
                <ShopImage src={logo} height="30" alt="shop logo" />
                <ShopName name={geti18nValue(companyName)} enableNameStyle={enableNameStyle} nameStyle={nameStyle} />
              </Link>
              {this.menuFloatRight && this.renderToggler()}
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand`}
                aria-labelledby={`offcanvasNavbarLabel-expand`}
                placement="start">
                <Offcanvas.Body className="header-offcanvas-body justify-content-between">
                  <KppNavbar toggleDrawer={this.toggleDrawer} />
                  <Nav key="nav-end-items" className={`justify-content-end kpp-nav`}>
                    <Search />
                    <Language />
                    <Currency />
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>

              <Nav className={`justify-content-end flex-row kpp-nav`}>
                {this.renderMember()}
                {this.renderMiniCart(this.defaultOverlayRef)}
              </Nav>
            </Container>
          </Navbar>
        </header>
      );
    }
    return (
      <>
        <React.Fragment>{this.renderDekstopGridLayout()}</React.Fragment>
        <React.Fragment>{this.renderMobileGridLayout()}</React.Fragment>
        <Sidebar
          open={this.state.showMenuDrawer}
          navBarStyle={this.getNabBarStyle()}
          closeDrawer={this.toggleDrawer.bind(null, false)}
          getElementClass={this.getElementClassName}
        />
        {this.renderMiniCartDrawer()}
        {defaultHeader}

        <DefaultModal
          show={this.state.showCheckCartModal}
          body={<h5>{t('ce01_pmpcore.react.confirmToLogout')}</h5>}
          centered={false}
          showConfirmButton={true}
          onConfirmHandler={this.handleConfirmCheckCartModal}
          showCloseButton={true}
          onCloseHandler={this.handleCloseCheckCartModal}
        />
      </>
    );
  }
}

export default Header;
