import { createSelector } from 'reselect';
import { RootState } from '@kopapro-redux/store';
import { ProductAttributeType, ProductGroup } from '@kopapro-redux/types/products';

export const getProductCategoryList = (state: RootState) => {
  if (state) {
    const categories = state.kopapro.productCategories.categories;
    return Object.values(categories);
  }
  return [];
};

export const getProductGroups = (state: RootState) => {
  if (state) {
    return state.kopapro.products.productGroups;
  }
  return undefined;
};

export const getProducts = (state: RootState) => {
  if (state) {
    return state.kopapro.products.products;
  }
  return undefined;
};

export const getAppliedFilter = (state: RootState) => {
  if (state) {
    return state.kopapro.products.appliedFilter;
  }
  return undefined;
};

export const getAttributeTypes = (state: RootState) => {
  if (state) {
    return state.kopapro.products.attributes;
  }
  return undefined;
};

export const getProductGroupByCode = createSelector(
  [getProductGroups, (state, gpCode: string) => gpCode],
  (productGroups, gpCode) => {
    if (productGroups) {
      return productGroups[gpCode];
    }
    return undefined;
  }
);

export const getProductAttributeTypesByGroupCode = createSelector(
  [getAttributeTypes, (state, gpCode: string) => gpCode],
  (attributeTypes, gpCode): ProductAttributeType[] => {
    let result: ProductAttributeType[] = [];
    if (attributeTypes && attributeTypes[gpCode]) {
      result = attributeTypes[gpCode].attrTypes;
    }
    return result;
  }
);

export const getProductGroupsByGpIds = createSelector(
  [getProductGroups, (state, gpIds: number[]) => gpIds],
  (productGroups, gpIds) => {
    const result: ProductGroup[] = [];
    if (productGroups) {
      const groups = Object.values(productGroups);
      gpIds.forEach((gpId) => {
        const group = groups.find((g) => g.m18ProGpId === gpId);
        if (group) {
          result.push(group);
        }
      });
    }
    return result;
  }
);

export const getProductGroupsByIds = createSelector(
  [getProductGroups, (_, ids: number[]) => ids],
  (productGroups, ids) => {
    const result: ProductGroup[] = [];
    if (productGroups) {
      const groups = Object.values(productGroups);
      ids.forEach((id) => {
        const group = groups.find((g) => g.m18ProId === id);
        if (group) {
          result.push(group);
        }
      });
    }
    return result;
  }
);

export const getProductGroupById = (state: RootState, id: number) => {
  if (state) {
    const groups = Object.values(state.kopapro.products.productGroups || {});
    return groups.find((g) => {
      return g.m18ProId === id;
    });
  }
  return undefined;
};

export const getProductByCode = createSelector(
  [getProducts, (state, proCode: string) => proCode],
  (products, proCode) => {
    if (products) {
      return products[proCode];
    }
    return undefined;
  }
);

export const getProductsByGroup = createSelector(
  [getProducts, (state, gpCode: string) => gpCode],
  (products, gpCode) => {
    if (products) {
      return Object.values(products).filter((product) => {
        return gpCode.length > 0 && product.gpCode === gpCode;
      });
    }
    return undefined;
  }
);

export const getProductCodeBySeoUrl = createSelector(
  [getProducts, (state, seoUrl: string) => seoUrl],
  (products, seoUrl) => {
    if (products) {
      const product = Object.values(products).find((product) => {
        return seoUrl.length > 0 && product.seoUrl === seoUrl;
      });
      if (product) {
        return product.code;
      }
    }
    return '';
  }
);

export const getDefaultProduct = createSelector(
    [
        getProducts,
        (state, gpCode: string) => gpCode
    ],
    (products, gpCode) => {
        if (products) {
            return Object.values(products).find(product => {
                return gpCode.length > 0 && product.gpCode === gpCode && product.defPro;
            })
        }
        return undefined;
    }
)

export const getProduct = createSelector(
  [
    getProducts,
    (state, gpCode: string, proId: string) => {
      return { gpCode, proId };
    },
  ],
  (products, { gpCode, proId }) => {
    if (products) {
      return Object.values(products).find((product) => {
        return gpCode.length > 0 && product.gpCode === gpCode && `${product.m18ProId}` === `${proId}`;
      });
    }
    return undefined;
  }
);
