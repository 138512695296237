import {
  getBannerImageFitClass,
  getCustomStyle,
  getFullLayoutClass,
  geti18nValue,
  resolveImageURL,
} from '@kopapro/utils/m18';
import { Component, ReactNode } from 'react';
import { BannerProps } from '@kopapro/components/shop/banner';
import utils from '@kopapro/utils/utils';
import Carousel from 'react-bootstrap/Carousel';
import { BannerMetaData } from '@kopapro-redux/types/shop';
import { bannerConfig as config } from '@kopapro/utils/config';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ImageType } from '@kopapro/utils/constants/constants';
import { RollingBanner } from '@kopapro/components/shop/banner/rollingBanner';
import { ResponsiveBanner } from '@kopapro/components/shop/banner/responsiveBanner';
import ShopImage from '@kopapro/components/commons/shopImage';
import { Dictionary } from '@kopapro-redux/types/utilities';

export default class Banner extends Component<BannerProps> {
  cardShadow = config.cardShadow;
  defaultHeight = config.defaultHeight;

  renderItem = (item: BannerMetaData, i: number, height: string | number) => {
    const { imageObjectFit } = this.props.content?.meta!;
    const { addUrl, url, infoPlateDto: plate, useOverlay, overlayDto } = item;

    let component = undefined;
    let componentProps = { to: '' };
    if (addUrl) {
      component = Link;
      componentProps = { to: url };
    }

    let plateStyle: Dictionary<string> = {};
    if (plate.useInfoPlate) {
      plateStyle = getCustomStyle({ ...plate, useUdfStyle: true, backgroundColor: plate.plateColor }, true);
    }

    return (
      <Carousel.Item key={`${item.photoCode}_${i}`} as={component} {...componentProps}>
        <ShopImage
          className={`d-block w-100  ${getBannerImageFitClass(imageObjectFit, 'cover')}`}
          src={resolveImageURL(item.photoCode, false, ImageType.webGallery)}
          height={height}
          overlay={useOverlay ? overlayDto : undefined}
          alt="banner"
        />

        {plate.useInfoPlate && (
          <Carousel.Caption>
            <div className={`info-plate-absolute info-plate-${plate?.animationClass}`}>
              <h5 className={`p-2 text-center`} style={{ ...plateStyle }}>
                {geti18nValue(plate.infoPlateObj)}
              </h5>
            </div>
          </Carousel.Caption>
        )}
      </Carousel.Item>
    );
  };

  render(): ReactNode {
    if (utils.isUndefined(this.props.content)) {
      return null;
    }
    const { compId } = this.props;
    const { meta, displayMode } = this.props.content!;
    const { data, allowUdfHeight, imageLayout, udfHeight } = meta;
    const height = allowUdfHeight ? udfHeight : this.defaultHeight;

    // displayMode = def, rolling, responsive
    let tarComponent: ReactNode = <></>;
    switch (displayMode) {
      case 'rolling':
        // Rolling Banner
        tarComponent = <RollingBanner {...this.props} />;
        break;
      case 'responsive':
        // TODO Responsive Banner
        // @kopapro/components/shop/banner/responsiveBanner
        tarComponent = <ResponsiveBanner {...this.props} />;
        break;
      default:
        tarComponent = (
          <div className="banner  ignore-height-xs" style={{ height, marginTop: 10 }}>
            <Carousel controls={data.length > 1} key={compId} style={{ height }}>
              {data.map((item, i) => {
                return this.renderItem(item, i, height);
              })}
            </Carousel>
          </div>
        );
    }

    return <div className={classnames('', getFullLayoutClass(imageLayout))}>{tarComponent}</div>;
  }
}
