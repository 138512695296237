import { Component, createRef, ReactNode, RefObject } from 'react';
import { BannerProps } from '@kopapro/components/shop/banner/index';
import ShopImage from '@kopapro/components/commons/shopImage';
import { InfoPlateData } from '@kopapro-redux/types/componentSetting';
import { Dictionary } from '@reduxjs/toolkit';
import { getBannerImageFitClass, getCustomStyle, geti18nValue } from '@kopapro/utils/m18';
import { Link } from 'react-router-dom';
import utils from '@kopapro-redux/utils/utils';
import { isM18PreviewMode } from '@kopapro-redux/utils/m18';
import { bannerConfig } from '@kopapro/utils/config';

interface RollingBannerState {
  scrollIndex: number;
}

export class RollingBanner extends Component<BannerProps, RollingBannerState> {
  defaultState: RollingBannerState = {
    scrollIndex: -1,
  };

  loopCount: number;
  autoPlayId: number;
  rollingOffset: number;
  scrollViewRef: RefObject<any>;
  chunkWidth: number;
  prevTime: DOMHighResTimeStamp;
  xOffset: number;

  constructor(props: BannerProps) {
    super(props);
    const { meta } = this.props.content!;
    const { data, rollingSpeed } = meta;
    const rollingImageWidth = meta.rollingImageWidth || 200;

    this.loopCount = 0;
    this.scrollViewRef = createRef();
    this.rollingOffset = this.getRollingOffset(rollingSpeed);
    this.chunkWidth = data.length * rollingImageWidth;
    this.autoPlayId = -1;
    this.prevTime = -1;
    this.xOffset = 0;

    this.state = this.defaultState;
  }

  componentDidMount(): void {
    this.calcLoopList();
    this.autoPlayId === -1 && window.requestAnimationFrame(this.autoPlay);
  }

  componentDidUpdate(prevProps: Readonly<BannerProps>, prevState: Readonly<RollingBannerState>, snapshot?: any): void {
    if (isM18PreviewMode()) {
      if (prevProps.content?.meta.rollingSpeed !== this.props.content?.meta.rollingSpeed) {
        const { meta } = this.props.content!;
        const { rollingSpeed } = meta;
        this.rollingOffset = this.getRollingOffset(rollingSpeed);
      }
      if (prevProps.content?.meta.rollingImageWidth !== this.props.content?.meta.rollingImageWidth) {
        const { meta } = this.props.content!;
        const { data } = meta;
        const rollingImageWidth = meta.rollingImageWidth || 200;
        this.chunkWidth = data.length * rollingImageWidth;
        this.calcLoopList();
        this.xOffset = 0;
        this.triggerUpdate(0);
      }
    }
  }

  componentWillUnmount(): void {
    this.autoPlayId !== -1 && window.cancelAnimationFrame(this.autoPlayId);
  }

  getRollingOffset = (rollingSpeed: 'verySlow' | 'slow' | 'medium' | 'high' | 'veryHigh' | undefined): number => {
    let rollingOffset: number;
    switch (rollingSpeed) {
      case 'verySlow':
        rollingOffset = 0.3;
        break;
      case 'slow':
        rollingOffset = 0.6;
        break;
      case 'medium':
        rollingOffset = 1;
        break;
      case 'high':
        rollingOffset = 1.4;
        break;
      case 'veryHigh':
        rollingOffset = 2;
        break;
      default:
        rollingOffset = 1;
    }
    return rollingOffset;
  };

  triggerUpdate = (nextXOffset: number) => {
    if (this.scrollViewRef.current) {
      const scrollView: HTMLElement = this.scrollViewRef.current.children[0];
      scrollView.style.transform = `translateX(-${nextXOffset}px)`;
    }
    const { scrollIndex } = this.state;
    const rollingImageWidth = this.props.content?.meta.rollingImageWidth || 200;
    const newScrollIndex: number = Math.floor(nextXOffset / rollingImageWidth);
    if (scrollIndex !== newScrollIndex) {
      this.setState({
        scrollIndex: newScrollIndex,
      });
    }
  };

  calcLoopList = () => {
    let scrollViewWidth: number = 0;
    if (this.scrollViewRef && this.scrollViewRef.current) {
      scrollViewWidth = this.scrollViewRef.current.offsetWidth;
    }
    this.loopCount = Math.ceil(scrollViewWidth / this.chunkWidth) + 1;
  };

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
   * > the animation will run faster on high refresh rate screens
   */
  autoPlay = (reqTime: DOMHighResTimeStamp) => {
    if (this.prevTime === -1) {
      this.prevTime = reqTime;
    }
    let nextXOffset: number = this.xOffset;
    nextXOffset += (this.rollingOffset * (reqTime - this.prevTime)) / 25;
    if (nextXOffset >= this.chunkWidth || nextXOffset < 0) {
      nextXOffset = nextXOffset % this.chunkWidth;
    }

    this.xOffset = nextXOffset;
    this.triggerUpdate(nextXOffset);
    this.prevTime = reqTime;
    this.autoPlayId = window.requestAnimationFrame(this.autoPlay);
  };

  triggerScroll = (side: 'right' | 'left') => {
    const rollingImageWidth = this.props.content?.meta.rollingImageWidth || 200;
    let nextXOffset: number = this.xOffset;
    switch (side) {
      case 'right':
        nextXOffset += rollingImageWidth;
        break;
      case 'left':
        nextXOffset -= rollingImageWidth;
        break;
    }
    nextXOffset -= (nextXOffset + this.chunkWidth) % rollingImageWidth;
    if (nextXOffset >= this.chunkWidth || nextXOffset < 0) {
      nextXOffset = (nextXOffset + this.chunkWidth) % this.chunkWidth;
    }

    this.xOffset = nextXOffset;
    this.triggerUpdate(nextXOffset);
  };

  renderInfoPlate = (infoPlate: InfoPlateData | undefined): ReactNode => {
    if (!infoPlate) {
      return <span className={`w-100`} style={{ minHeight: 100 }} />;
    }
    const header = geti18nValue(infoPlate.infoPlateObj);
    if (utils.isEmpty(header)) {
      return null;
    }

    let plateStyle: Dictionary<string> = {};
    if (infoPlate.useInfoPlate) {
      plateStyle = getCustomStyle({ ...infoPlate, useUdfStyle: true, backgroundColor: infoPlate.plateColor }, true);
    }
    return (
      <div className={`info-plate-${infoPlate.animationClass} w-100`} style={{ minHeight: 100 }}>
        <h5 className={`d-flex h-100 justify-content-center align-items-center`} style={{ ...plateStyle }}>
          {header}
        </h5>
      </div>
    );
  };

  render() {
    const { scrollIndex } = this.state;
    const { meta, compId } = this.props.content!;
    const {
      data,
      rollingImageHeight,
      infoPlatePosMobile,
      infoPlatePosDesktop,
      allowUdfHeight,
      udfHeight,
      rollingImageWidth,
      imageObjectFit,
    } = meta;
    const infoPlate: InfoPlateData | undefined = data.at(scrollIndex)?.infoPlateDto;

    const bannerHeight: string | number = allowUdfHeight ? udfHeight : bannerConfig.defaultHeight;
    const imgHeight: string | number =
      rollingImageHeight && typeof bannerHeight === 'number'
        ? Math.min(bannerHeight, rollingImageHeight)
        : bannerHeight;

    return (
      <div
        className={`d-flex flex-column flex-md-row 
          flex-column${infoPlatePosMobile === 'bottom' ? '-reverse' : ''} 
          flex-md-row${infoPlatePosDesktop === 'right' ? '-reverse' : ''}`}
        style={{ height: bannerHeight }}>
        <div
          style={{ flex: 1 }}
          className={`info-plate d-${infoPlatePosMobile === 'hidden' ? 'none' : 'flex'} 
            d-md-${infoPlatePosDesktop === 'hidden' ? 'none' : 'flex'}`}>
          {this.renderInfoPlate(infoPlate)}
        </div>

        <div
          style={{ flex: 3, height: imgHeight }}
          className={`d-flex overflow-hidden position-relative align-self-center w-100`}
          ref={this.scrollViewRef}
          onMouseOver={(e) => {
            this.prevTime = -1;
            window.cancelAnimationFrame(this.autoPlayId);
          }}
          onMouseLeave={(e) => {
            window.requestAnimationFrame(this.autoPlay);
          }}>
          <div
            className={`d-flex align-items-center overflow-visible rolling-banner-${compId}`}
            style={{ transform: `translateX(0px)` }}>
            {[...Array(this.loopCount)].map((_, j) => {
              return data.map((item, i) => {
                const TarComp = item.addUrl ? Link : 'div';
                const extProps = item.addUrl ? { to: item.url } : { to: '' };
                return (
                  <TarComp className={`d-flex`} key={`rolling-banner-${compId}-item-${j}-${i}`} {...extProps}>
                    <ShopImage
                      src={item.photoCode}
                      width={rollingImageWidth || 200}
                      height={rollingImageHeight}
                      className={getBannerImageFitClass(imageObjectFit, 'cover')}
                      overlay={item.overlayDto}
                      alt="banner"
                    />
                  </TarComp>
                );
              });
            })}
          </div>

          <button
            className={`btn carousel-control-prev`}
            onClick={() => {
              this.triggerScroll('left');
            }}>
            <span className={`carousel-control-prev-icon`} />
          </button>

          <button
            className={`btn carousel-control-next`}
            onClick={() => {
              this.triggerScroll('right');
            }}>
            <span className={`carousel-control-next-icon`} />
          </button>
        </div>
      </div>
    );
  }
}
