import utils from '@kopapro-redux/utils/utils';
import { GoogleProps } from '@kopapro/components/misc/integration/google';

export const gtag = (...args: any[]) => {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
    }

    window.gtag(...args);
  }
};

export default function Google(props: GoogleProps) {
  const { setupRecaptcha, google } = props;

  if (setupRecaptcha) {
    const initGoogle = function (d: Document, s: string, id: string) {
      let js: HTMLScriptElement;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.async = true;
      js.defer = true;
      js.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
      js.onload = function () {
        window.dispatchEvent(new Event('recaptcha_ready'));
      };
      //@ts-ignore
      fjs.parentNode.insertBefore(js, fjs);
    };
    initGoogle(document, 'script', 'google-captcha');
  }

  // TODO: GA

  if (google) {
    const { googleAnalytics, googleTagManager } = google;

    if (googleAnalytics) {
      const isGAEnable = googleAnalytics.isEnable;
      const traceId = googleAnalytics.traceId;
      if (isGAEnable && utils.isNotEmpty(traceId)) {
        const initGA = function (d: Document, s: string, id: string) {
          let js: HTMLScriptElement;
          const fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s) as HTMLScriptElement;
          js.id = id;
          js.async = true;
          js.src = 'https://www.googletagmanager.com/gtag/js?id=' + traceId;

          gtag('js', new Date());
          gtag('config', traceId);

          //@ts-ignore
          fjs.parentNode.insertBefore(js, fjs);
        };
        initGA(document, 'script', 'gtm-ga');
      }
    }

    if (googleTagManager) {
      const { gtmId, isEnable } = googleTagManager;
      if (isEnable && utils.isNotEmpty(gtmId)) {
        (function (w: any, d: any, s: any, l: any, i: any) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', gtmId);

        return (
          <noscript>
            <iframe
              title="gtm-iframe"
              src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}></iframe>
          </noscript>
        );
      }
    }
  }

  return null;
}
