
import { MiscType, ProductType, ShopMetaType } from '@kopapro-redux/action-types';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import KopaproApi from '@kopapro-redux/api';
import {
  getMetaComponentsSuccess,
  getMetaLayoutSuccess,
  getMetaSetupSuccess,
  getMetaRequestSuccess,
  receivedShopBanners,
} from '@kopapro-redux/actions/shop';
import { Banner, ShopCurrencySetup, ShopLayout, ShopSetup } from '@kopapro-redux/types/shop';
import { ProQuickSearch, ShopComponentSetting } from '@kopapro-redux/types/componentSetting';
import { setCurrency, setDisplayLanguage } from '@kopapro-redux/actions/user';
import { handleI18n } from '@kopapro-redux/sagas/entities/userSaga';
import { getProductFilters, isLanguageValid } from '@kopapro-redux/selectors/entities/shop';
import { isCurrencyValid } from '@kopapro-redux/selectors/entities/currency';
import { deleteCookie, getCookie, getStorageItem, setCookie } from '@kopapro-redux/utils/cookies';
import { QueryList } from '@kopapro-redux/types/general';
import { AxiosError } from 'axios';
import { updateKopaproExpired } from '@kopapro-redux/actions/misc';
import utils from '@kopapro-redux/utils/utils';
import { isM18PreviewMode, keepValueFromSource } from '@kopapro-redux/utils/m18';
import { ProductFilter } from '@kopapro-redux/types/products';
import sha1 from 'crypto-js/sha1';
import hex from 'crypto-js/enc-hex';

function* metaLayoutSaga() {
  const response: ShopLayout = yield call(KopaproApi.getMetaLayout);
  return response;
}

function* metaCompSettingSaga() {
  const response: ShopComponentSetting = yield call(KopaproApi.getMetaCompSetting);
  return response;
}

function* metaSetupSaga() {
  const response: { setup: ShopSetup } = yield call(KopaproApi.getMetaSetup);
  return response;
}

function* getCurrencySaga() {
  const currency: { data: ShopCurrencySetup[] } = yield call(KopaproApi.getCurrency);
  return currency;
}

// all in one
export function* getShopMetaSaga() {
  try {
    let layout: ShopLayout;
    let componentSetting: ShopComponentSetting;
    let setup: ShopSetup;

    const [a, b, setupResp, currency]: [
      ShopLayout,
      ShopComponentSetting,
      { setup: ShopSetup },
      { data: ShopCurrencySetup[] }
    ] = yield all([call(metaLayoutSaga), call(metaCompSettingSaga), call(metaSetupSaga), call(getCurrencySaga)]);
    layout = a;
    componentSetting = b;

    yield put(getMetaComponentsSuccess(componentSetting));

    setup = { ...setupResp.setup, currency: currency.data };

    if (isM18PreviewMode()) {
      const tempLayout: ShopLayout = utils.parseJSON(getStorageItem('rnbase_previewJson_layout'));
      if (tempLayout) {
        layout = { ...layout, ...tempLayout };
      }

      const tempComponent: ShopComponentSetting = utils.parseJSON(getStorageItem('rnbase_previewJson_compsetting'));
      if (tempComponent) {
        const previewComponent = keepValueFromSource(componentSetting, tempComponent, 'proQuickSearch', 'info');
        componentSetting = { ...componentSetting, ...previewComponent };
      }

      const tempSetup: ShopSetup = utils.parseJSON(getStorageItem('rnbase_previewJson_setup'));
      if (tempSetup) {
        setup = { ...setup, ...tempSetup };
      }
    }

    // get cookie
    const announcementHash = getCookie('announcement_hash');
    let newContent = {};
    if (componentSetting.shopinfo && componentSetting.shopinfo[0] && componentSetting.shopinfo[0].announcement) {
      newContent = componentSetting.shopinfo[0].announcement;
    }

    const newAnnouncementHash = sha1(JSON.stringify(newContent)).toString(hex);
    if (announcementHash !== newAnnouncementHash) {
      // update cookie and state
      // showAnnouncement true
      deleteCookie('close_announcement');
      setCookie('announcement_hash', newAnnouncementHash, 1);
      yield put({ type: MiscType.RESET_ANNOUNCEMENT });
    }

    yield put(getMetaLayoutSuccess(layout));
    yield put(getMetaSetupSuccess(setup));

    const { language, enableLangPath, excludeDefaultLangInURL } = setup.default;
    const languages = setup.language;
    if (setup && setup.default && language) {
      // apply default lang
      let lang = language;

      const storedLang = getStorageItem('lang');
      const isStoredLangValid: boolean = yield select(isLanguageValid, storedLang);
      if (!isM18PreviewMode() && storedLang.length > 0 && isStoredLangValid) {
        // use cookie if exists
        lang = storedLang;
      }
      const pathname = window.location.pathname;
      const pathnameParts = pathname.split('/');
      if (enableLangPath) {
        // set back to default lang first
        lang = language;
        let match = '';
        if (pathnameParts.length >= 2 && languages && Object.keys(languages)) {
          const langPathCode = pathnameParts[1];
          match =
            Object.keys(languages).find(function (id) {
              return langPathCode === `${id.toLocaleLowerCase()}`;
            }) || '';
          if (match) {
            // if lang path param exists
            lang = match;
          }
        }
        // check current url contain lang path
        const isValid = utils.isNotEmpty(match);
        const isDefaultLang = lang === language;
        if (isValid && excludeDefaultLangInURL && isDefaultLang) {
          // if path exists, trim the default lang path
          let trimedPathname = pathname;
          if (trimedPathname.charAt(trimedPathname.length - 1) === '/') {
            trimedPathname = trimedPathname.slice(0, -1);
          }

          const newPath = `${trimedPathname.replace(lang.toLocaleLowerCase(), '')}${window.location.search}`;
          if (pathname !== newPath) {
            window.history.pushState({ time: new Date().getTime() }, '', newPath);
          }
        }
        if (!isValid && !(excludeDefaultLangInURL && isDefaultLang)) {
          const newPath = `/${language.toLocaleLowerCase()}${pathname}${window.location.search}`;
          window.history.pushState({ time: new Date().getTime() }, '', newPath);
        }
      }
      const saveInCookie = storedLang !== lang;

      // update state
      yield put(setDisplayLanguage(lang, saveInCookie));

      // update i18next
      handleI18n(lang);
    }

    // appply default currency
    yield call(applyDefaultCurrency, setup);

    // init google recaptcha

    yield put(getMetaComponentsSuccess(componentSetting));
    yield call(initFilter);
    yield put(getMetaRequestSuccess());

    // get meta banners
    let banners: QueryList<Banner>;
    banners = yield call(KopaproApi.getBanners);
    if (isM18PreviewMode()) {
      const data: Banner[] = utils.parseJSON(getStorageItem('rnbase_previewJson_banners'));
      if (utils.isNotEmptyList(data)) {
        banners = { ...banners, data };
      }
    }
    yield put(receivedShopBanners(banners));
  } catch (e) {
    if (e instanceof Error) {
      if (e instanceof AxiosError) {
        const statusCode = e.response?.status;
        if (statusCode && statusCode >= 400 && statusCode <= 499) {
          // if not published yet, will go to kopapro.com
          window.location.href = 'https://kopapro.com';
        }
      } else if (e.message === 'kopapro_expired') {
        yield put(updateKopaproExpired(true));
      } else {
        console.error(e);
      }
    }
  }
}

function* shopMetaSaga() {
  yield all([takeLatest(ShopMetaType.GET_SHOP_META_REQUEST, getShopMetaSaga)]);
}

function* applyDefaultCurrency(setup: ShopSetup) {
  if (setup && setup.default && setup.default.currency) {
    let currency = setup.default.currency;

    const storedCurrency = getStorageItem('currency');
    const isValid: boolean = yield select(isCurrencyValid, storedCurrency);
    if (storedCurrency.length > 0 && isValid) {
      currency = storedCurrency;
    }
    // update state
    yield put(setCurrency(currency, storedCurrency === ''));
  }
}

function* initFilter() {
  const storedFilter: ProductFilter | undefined = utils.parseJSON(getStorageItem('kpp_filter'));
  const proQuickSearch: ProQuickSearch | undefined = yield select(getProductFilters);

  if (storedFilter && proQuickSearch) {
    const newFilter: ProductFilter = { priceFrom: storedFilter.priceFrom, priceTo: storedFilter.priceTo };
    const subFilters = Object.values(proQuickSearch.info);
    const enableFilters: any = Object.assign({}, proQuickSearch.enable);

    const enabledFilters = subFilters.filter((f) => {
      let enableField = `enable${utils.capitalizeFirstLetter(f.key)}`;
      return enableFilters[enableField];
    });
    enabledFilters.forEach((filter) => {
      const { key, value } = filter;
      const storedValue: string[] = (storedFilter[key] as string[]) || [];
      const intersection: string[] = value.filter((element) => storedValue.includes(element)) || [];
      newFilter[key] = intersection;
    });

    // prevent old data in local storage
    yield put({ type: ProductType.APPLY_FILTER, payload: newFilter });
  }
}


export default shopMetaSaga;
